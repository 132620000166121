/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import template from "templates/rubric/rubric_builder.hbs";
import { View } from "backbone.marionette";
import CriteriaView from "views/rubric/criteria";
import RangesView from "views/rubric/ranges";
import RangeModel from "models/range";
import FieldModel from "models/field";
import ShortTextInputView from "views/property_editor/short_text_input";

export default class RubricBuilder extends View.extend({
  template,
  collections: {},

  regions: {
    criteria: '[data-region="criteria"]',
    bodyWrapper: '[data-region="body-wrapper"]',
    addButtons: '[data-region="add-buttons"]'
  },

  events: {
    'click [data-trigger="rangeAdd"]': "handleRangeAdd",
    'click [data-trigger="criteriaAdd"]': "handleCriteriaAdd"
  },

  ui: {
    rangeSnap: ".range-add-snap",
    criteriaSnap: ".criteria-add-snap",
    cells: '[data-region="cells"]'
  }
}) {
  newRange() {
    const range = new RangeModel({ index: this.collections.ranges.length });
    const modal = new ShortTextInputView({
      model: range,
      property: "name",
      saveClasses: "update-button",
      saveLabel: "Add Range",
      inputLabel: "What would you like to call this range?",
      vent: this.vent
    });
    this.listenTo(modal, "model:updated", function(e) {
      return this.model.get("ranges").add(range);
    });
    return Radio.channel("app").trigger("modal:open", modal);
  }

  handleRangeAdd(event) {
    event.preventDefault();
    if (this.model.availableRanges()) {
      return this.newRange();
    }
    if (this.model.getHigh() < 100) {
      this.model.setHigh(this.model.getHigh() + 1);
      return this.newRange();
    }
    return this.trigger("error:add", {
      level: "notice",
      msg:
        "Before you can add another range to this rubric, you must increase the number of available points by changing the highest possible score field, above."
    });
  }

  handleCriteriaAdd(event) {
    event.preventDefault();
    const field = new FieldModel({ index: this.collections.criteria.length });
    const modal = new ShortTextInputView({
      model: field,
      property: "name",
      saveClasses: "update-button",
      saveLabel: "Add Criteria",
      inputLabel: "What would you like to call this criteria?",
      vent: this.vent
    });
    this.listenTo(modal, "model:updated", function(e) {
      return this.model.get("fields").add(field);
    });
    return Radio.channel("app").trigger("modal:open", modal);
  }

  showCriteriaSnap() {
    if (this.collections.criteria.length > 3) {
      $(this.getUI("criteriaSnap")).css("display", "inline-block");
      return $(".criteria-bar").css("visibility", "hidden");
    }
    $(this.getUI("criteriaSnap")).css("display", "none");
    return $(".criteria-bar").css("visibility", "visible");
  }

  showRangeSnap() {
    if (this.collections.ranges.length <= 3) {
      $(this.getUI("rangeSnap")).css("display", "none");
      return $(".range-bar").css("visibility", "visible");
    }
    if (this.collections.ranges.length === 4) {
      $(".range-bar").css("visibility", "hidden");
      return $(this.getUI("rangeSnap")).css({
        display: "inline-block",
        width: "116px"
      });
    }

    if (
      $(".cells").length && $(".cells").position().left ===
      -(this.model.get("ranges").length - 4) * 218
    ) {
      $(".range-bar").css("visibility", "hidden");
      return $(this.getUI("rangeSnap")).css({
        display: "inline-block",
        width: "116px",
        "z-index": "10"
      });
    }
    $(".range-bar").css("visibility", "hidden");
    return $(this.getUI("rangeSnap")).css({
      display: "inline-block",
      width: "55px"
    });
  }

  initialize(options) {
    this.vent = options.vent;
    this.collections.ranges = this.model.get("ranges");
    this.collections.criteria = this.model.get("fields");

    this.listenTo(this.collections.criteria, "add remove", function(event) {
      return this.showCriteriaSnap();
    });

    this.listenTo(this.collections.ranges, "add remove", function(event) {
      return this.showRangeSnap();
    });

    return this.listenTo(this.vent, "sliders:displayed", function(event) {
      return this.showRangeSnap();
    });
  }

  onDomRefresh() {

    this.showCriteriaSnap();

    this.showChildView(
      "criteria",
      new CriteriaView({
        collection: this.collections.criteria,
        vent: this.vent
      })
    );
    this.showChildView(
      "bodyWrapper",
      new RangesView({
        collection: this.collections.ranges,
        rubric: this.model,
        criteria: this.collections.criteria,
        vent: this.vent
      })
    );
  }

  onRender() {
    this.showCriteriaSnap();
    return this.showRangeSnap();
  }
}
