/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/assets/asset_detail.hbs";
import { View } from "backbone.marionette";
import VideoPlayerView from "views/assets/player/video_player";
import ImagePlayerView from "views/assets/player/image_player";
import ProcessingWarningView from "views/assets/player/processing_warning";
import AnnotatorView from "views/assets/annotator/annotator";
import AnnotatorCanvasView from "views/assets/annotator/annotator_canvas";
import MockCanvasView from "views/assets/annotator/mock_canvas";
import AnnotationsView from "views/assets/annotations/annotations";
import EmbedModalView from "views/modal/modal_embed";

export default class AssetShowLayout extends View.extend({
  template,

  ui: {
    detailClose: '[data-behavior="detail-close"]',
    playerColumn: '[data-behavior="player-column"]',
    annotationsColumn: '[data-behavior="annotations-column"]',
    message: '[data-behavior="message"]',
    showEmbedHelp: '[data-behavior="show-embed-help"]',
  },

  triggers: {
    "click @ui.detailClose": "detail:close",
    "click @ui.showEmbedHelp": "open:embed:modal",
  },

  regions: {
    player: '[data-region="player"]',
    annotations: '[data-region="annotations"]',
    annotationsStage: '[data-region="annotations-stage"]',
    annotator: '[data-region="annotator"]',
    annotatorCanvas: '[data-region="annotator-canvas"]'
  }
}) {

  onOpenEmbedModal() {
    return Radio.channel("app").trigger(
      "modal:open",
      new EmbedModalView({ model: this.model })
    );
  }

  handleMessageShow(data) {
    const { msg } = data;
    this.getUI("message").html(msg);
    return this.getUI("message").addClass("open");
  }

  handleMessageHide(data) {
    this.getUI("message").html("");
    return this.getUI("message").removeClass("open");
  }

  selectPlayerView() {
    let playerView;
    const viewConstructorArguments = { model: this.model, vent: this.vent };
    if (this.model.get("attachment_state") === "processed") {
      const family = this.model.get("family");
      switch (family) {
        case "video":
          playerView = new VideoPlayerView(viewConstructorArguments);
          break;
        case "image":
          playerView = new ImagePlayerView(viewConstructorArguments);
          break;
        case "audio":
          playerView = new VideoPlayerView(viewConstructorArguments);
          break;
        default:
          console.log("Unexpected media family."); // eslint-disable-line no-console
      }
    } else {
      playerView = new ProcessingWarningView(viewConstructorArguments);
    }
    return playerView;
  }

  pickAnnotationsView(asset) {
    if(!asset.showAnnotations()) return;

    let annotationsView;
    return (annotationsView = new AnnotationsView({
      model: asset,
      submission: this.submission,
      vent: this.vent
    }));
  }

  onRender() {
    const annotationsView = this.pickAnnotationsView(this.model);
    const annotatorView = this.pickAnnotatorView(this.model);
    const canvasView = this.pickCanvasView(this.model);
    const playerView = this.selectPlayerView();

    this.showChildView("player", playerView);

    if (annotatorView) {
      this.showChildView("annotator", annotatorView);
    }
    if (canvasView) {
      this.showChildView("annotatorCanvas", canvasView);
    }
    if (annotationsView) {
      this.showChildView("annotations", annotationsView);
    }
  }

  pickCanvasView(asset) {
    const canDisplayCanvas = asset.allowsVisibleAnnotation();
    if (canDisplayCanvas === true && asset.showAnnotations()) {
      return new AnnotatorCanvasView({ model: this.model, vent: this.vent });
    }
    return new MockCanvasView({ model: this.model, vent: this.vent });
  }

  pickAnnotatorView(asset) {
    if(asset.attributes.embedded) return;
    // Stub method - currently all assets can be annotated, but this may change in the future.
    return new AnnotatorView({ model: this.model, vent: this.vent, submission: this.submission });
  }

  serializeData() {
    const data = super.serializeData();
    data.hasDuration = this.model.hasDuration();
    data.showAnnotations = this.model.showAnnotations();
    data.embedSnippet = this.model.attributes.embeddable ? this.model.attributes.embed_snippet : null;
    data.showEmbedDialog = this.model.attributes.embeddable && !this.model.attributes.embedded;

    return data;
  }

  onDetailClose() {
    let url;
    const context = this.model.get("creator_type").toLowerCase() + "s";
    if (this.viewContext === "coursemap") {
      url = `courses/${
        this.courseId
      }/${context}/evaluations/creator/${this.model.get(
        "creator_id"
      )}/project/${this.model.get("project_id")}`;
    } else {
      url = `courses/${this.courseId}/${context}/creator/${this.model.get(
        "creator_id"
      )}/project/${this.model.get("project_id")}`;
    }
    return window.Vocat.router.navigate(url, true);
  }

  initialize(options) {
    this.viewContext = options.context;
    this.submission = options.submission;

    this.courseId = window.VocatCourseId;
    this.vent = Radio.channel(`asset-${this.model.id}`);

    this.listenTo(
      this.vent,
      "request:message:show",
      this.handleMessageShow,
      this
    );
    return this.listenTo(
      this.vent,
      "request:message:hide",
      this.handleMessageHide,
      this
    );
  }
}
