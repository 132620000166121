/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import ClosesOnUserAction from "behaviors/closes_on_user_action";

import { View } from "backbone.marionette";

export default class DropdownView extends View.extend({
  adjusted: false,
  allowAdjustment: true,
  originalBodyPadding: null,

  triggers: {
    "click @ui.trigger": "click:trigger"
  },

  ui: {
    trigger: '[data-behavior="toggle"]',
    dropdown: '[data-behavior="dropdown-options"]'
  },

  behaviors: {
    closesOnUserAction: {
      behaviorClass: ClosesOnUserAction
    }
  }
}) {
  onClickTrigger() {
    if (this.$el.hasClass("open")) {
      return this.close();
    }
    return this.open();
  }

  adjustPosition() {
    const dd = this.$el.find('[data-behavior="dropdown-options"]');
    if (this.adjusted === false && this.getOption("allowAdjustment") === true) {
      if (dd.offset().left < 0) {
        dd.css({ left: 0 });
        dd.css({ right: "auto" });
      } else if (dd.width() + dd.offset().left - $("html").width() < 25) {
        dd.css({ right: 0 });
        dd.css({ left: "auto" });
      }
      return (this.adjusted = true);
    }
  }

  checkBodyPadding() {
    const dd = this.$el.find('[data-behavior="dropdown-options"]');
    const height = dd.outerHeight();
    const requiredHeight =
      this.$el.offset().top + height + this.$el.outerHeight();
    const documentHeight = $(document).height();
    if (requiredHeight > documentHeight) {
      this.originalBodyPadding = parseInt(
        $("body")
          .css("padding-bottom")
          .replace("px", ""),
        10
      );
      const newPadding =
        parseInt(requiredHeight, 10) -
        parseInt(documentHeight, 10) +
        this.originalBodyPadding +
        60;
      return $("body").css({ paddingBottom: newPadding });
    }
  }

  restoreBodyPadding() {
    if (this.originalBodyPadding !== null) {
      $("body").css({ paddingBottom: this.originalBodyPadding });
      return (this.originalBodyPadding = null);
    }
  }

  open() {
    this.checkBodyPadding();
    this.$el.removeClass("closed");
    this.$el.addClass("open");
    this.adjustPosition();
    return this.triggerMethod("opened");
  }

  close() {
    this.$el.removeClass("open");
    this.$el.addClass("closed");
    this.triggerMethod("closed");
    return this.restoreBodyPadding();
  }

  initialize(options) {
    this.vent = options.vent;
    this.$dropdown = this.$el.find(this.getUI("dropdown"));
    this.$trigger = this.$el.find(this.getUI("trigger"));

    if (!this.$el.hasClass("dropdown-initialized")) {
      return this.$el.addClass("dropdown-initialized");
    }
  }
}
