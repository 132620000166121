/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default function(ranges, highBound) {
  if (ranges.length > 0) {
    return highBound - ranges.length + 1;
  }
  return highBound - 1;
}
