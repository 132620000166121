/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Row from "views/course_map/row";

import { CollectionView } from "backbone.marionette";

export default class MatrixView extends CollectionView.extend({
  tagName: "tbody",
  childView: Row
}) {
  childViewOptions() {
    return {
      creatorType: this.creatorType,
      collection: this.collections.project,
      collections: this.collections,
      courseId: this.courseId,
      vent: this.vent
    };
  }

  setupListeners() {
    return this.listenTo(this.collections.submission, "sync", function() {
      return this.render();
    });
  }

  onRender() {
    return this.vent.trigger("redraw");
  }

  initialize(options) {
    this.collections = options.collections;
    this.courseId = options.courseId;
    this.vent = options.vent;
    this.creatorType = options.creatorType;
    return this.setupListeners();
  }
}
