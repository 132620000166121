require('styles/stylesheets/main.scss');
window.$ = window.jquery = window.jQuery = require("jquery");
require('chosen');
require.context('styles/images', true);
require("vocat");

$(() => {
  // Start Vocat App
  window.Vocat.start()
});
