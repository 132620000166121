import { View } from "backbone.marionette";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */


export default class ManageOrganizationView extends View.extend({
  template: false,

  ui: {
    ldapEnabled: '[data-class="ldap-enabled"]',
    ldapFields: '[data-class="ldap-fields"]'

  },

  triggers: {
    'change @ui.ldapEnabled': 'change:ldap:enabled'
  }
}) {
  onChangeLdapEnabled() {
    const val = this.getUI('ldapEnabled').val();
    if (val === 'true') {
      console.log('showing');
      return this.getUI('ldapFields').show();
    } else {
      console.log('hiding');
      return this.getUI('ldapFields').hide();
    }
  }


  initialize() {
    return this.render();
  }

  onRender(options) {
    return this.onChangeLdapEnabled();
  }
}
