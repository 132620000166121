/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/assets/new_asset_footer.hbs";

import { View, getOption } from "backbone.marionette";

export default class NewAssetFooter extends View.extend({
  template,

  ui: {
    stopManagingLink: '[data-behavior="stop-manage-link"]'
  }
}) {
  preventManageClose() {
    return this.getUI("stopManagingLink").css({ display: "none" });
  }

  allowManageClose() {
    return this.getUI("stopManagingLink").css({ display: "inline-block" });
  }

  initialize(options) {
    return (this.vent = getOption(this, "vent"));
  }
}
