/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/notification/notification_message.hbs";

import { View } from "backbone.marionette";

export default class NotificationMessage extends View.extend({
  template,
  lifetime: 10000,
  isFlash: true,

  triggers: {
    'click [data-behavior="close-message"]': "closeMessage"
  }
}) {
  onCloseMessage() {
    return this.trigger("view:expired");
  }

  className() {
    return `alert alert-${this.model.get("level")}`;
  }

  initialize() {
    const lifetime = parseInt(this.model.get("lifetime"), 10);
    if (lifetime > 0) {
      return (this.lifetime = lifetime);
    }
  }

  onRender() {
    return setTimeout(() => {
      return this.trigger("view:expired");
    }, this.lifetime);
  }
}
