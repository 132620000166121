/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default function(str, len) {
  let newStr;
  if (str.length > len) {
    newStr = str.substr(0, len + 1);
    while (newStr.length) {
      const ch = newStr.substr(-1);
      newStr = newStr.substr(0, -1);
      if (ch === " ") {
        break;
      }
    }
    if (newStr === "") {
      newStr = str.substr(0, len);
    }
  } else {
    newStr = str;
  }
  return newStr + "...";
}
