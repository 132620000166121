/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import PortfolioSubmissionItem from "views/portfolio/portfolio_submissions_item";
import { CollectionView } from "backbone.marionette";
import EmptyView from "views/portfolio/portfolio_submissions_item_empty";

export default class PortfolioSubmissionsView extends CollectionView.extend({
  childView: PortfolioSubmissionItem,
  emptyView: EmptyView
}) {
  onDomRefresh() {}

  onRender() {}
}
