var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "these users";
},"3":function(container,depth0,helpers,partials,data) {
    return "this user\n		";
},"5":function(container,depth0,helpers,partials,data) {
    return "them";
},"7":function(container,depth0,helpers,partials,data) {
    return "him/her";
},"9":function(container,depth0,helpers,partials,data) {
    return "Users";
},"11":function(container,depth0,helpers,partials,data) {
    return "User";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alerts\">\n	<div class=\"alert alert-notice\" style=\"display: block;\">\n		<aside class=\"alert--label\"></aside>\n		<p>\n			"
    + alias2(__default(require("../../../helpers/cardinal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"contacts_count") : depth0),true,{"name":"cardinal","hash":{},"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":5,"column":35}}}))
    + " of the users you entered do not have a Vocat account.\n			Press the confirm button below to invite "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_contacts") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":44},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + " to Vocat and enroll "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_contacts") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":81}}})) != null ? stack1 : "")
    + " in this course.\n		</p>\n\n		<p>\n			<small>The following users will be invited: "
    + alias2(__default(require("../../../helpers/truncate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"contact_emails") : depth0),900,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":78}}}))
    + "</small>\n		</p>\n		<p>\n			<a href=\"#\" data-behavior=\"submit-invite-and-enroll\" class=\"primary-button new-button\">Invite\n				New "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_contacts") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":57}}})) != null ? stack1 : "")
    + "</a>\n			<a href=\"#\" data-behavior=\"cancel-invite-and-enroll\" class=\"secondary-button\">Cancel</a>\n		</p>\n	</div>\n";
},"useData":true});