export default {
  admin: {
    'admin/courses/:course/evaluators': 'evaluatorEnrollment',
    'admin/courses/:course/assistants': 'assistantEnrollment',
    'admin/courses/:course/creators': 'creatorEnrollment',
    'admin/users/:user/courses': 'courseEnrollment'
  },
  course: {
    'courses/:course/manage/enrollment': 'creatorEnrollment',
    'courses/:course/manage/projects': 'courseManageProjects',
    'courses/:course/manage/projects/new': 'courseNewProject'
  },
  coursemap: {
    'courses/:course/users/evaluations': 'userCourseMap',
    'courses/:course/groups/evaluations': 'groupCourseMap',
    'courses/:course/evaluations/assets/:asset': 'assetDetail',
    'courses/:course/users/evaluations/creator/:creator': 'userCreatorDetail',
    'courses/:course/groups/evaluations/creator/:creator': 'groupCreatorDetail',
    'courses/:course/users/evaluations/project/:project': 'userProjectDetail',
    'courses/:course/groups/evaluations/project/:project': 'groupProjectDetail',
    'courses/:course/users/evaluations/creator/:creator/project/:project': 'userSubmissionDetail',
    'courses/:course/users/evaluations/creator/:creator/project/:project/asset/:asset': 'userSubmissionDetailAsset',
    'courses/:course/groups/evaluations/creator/:creator/project/:project': 'groupSubmissionDetail',
    'courses/:course/groups/evaluations/creator/:creator/project/:project/asset/:asset': 'groupSubmissionDetailAsset'
  },
  group: {
    'courses/:course/manage/groups': 'index'
  },
  page: {
    'pages/help_dev': 'helpDev',
    'pages/modal_dev': 'modalDev'
  },
  project: {
    'courses/:course/users/project/:project': 'userProjectDetail'
  },
  rubric: {
    'courses/:course/manage/rubrics/new': 'new',
    'admin/rubrics/new': 'new',
    'rubrics/new': 'new',
    'courses/:course/manage/rubrics/:rubric/edit': 'edit',
    'admin/rubrics/:rubric/edit': 'editWithoutCourse',
    'rubrics/:rubric/edit': 'editWithoutCourse'
  },
  submission: {
    'courses/:course/users/creator/:creator/project/:project': 'creatorProjectDetail',
    'courses/:course/groups/creator/:creator/project/:project': 'groupProjectDetail',
    'courses/:course/view/project/:project': 'creatorProjectDetail',
    'courses/:course/groups/creator/:creator': 'groupDetail',
    'courses/:course/users/creator/:creator': 'creatorDetail',
    'courses/:course/assets/:asset': 'assetDetail',
  },
  embed: {
    'embed/assets/:asset': 'assetDetail'
  }
}
