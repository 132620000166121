/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from 'templates/admin/enrollment/input_empty.hbs';

import { View } from "backbone.marionette";

export default class EnrollmentInputEmpty extends View.extend({
  template: template,
  tagName: 'li'
}) {
  initialize(options) {
    return this.collectionType = options.collectionType;
  }

  serializeData() {
    return {
    isUserCollection: this.collectionType === 'user',
    isCourseCollection: this.collectionType === 'course'
    };
  }
}
