/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/admin/enrollment_layout.hbs";
import { View } from "backbone.marionette";
import EnrollmentList from "views/admin/enrollment/list";
import EnrollmentInput from "views/admin/enrollment/input";
import EnrollmentBulkInput from "views/admin/enrollment/bulk_input";
import SingleInvite from "views/admin/enrollment/invite";
import Flash from "views/flash/flash_messages";

export default class CreatorEnrollmentLayout extends View.extend({
  listType: "users",
  template,
  label: "",

  ui: {
    studentInput: '[data-behavior="student-input"]',
    labelContainer: '[data-behavior="label-container"]'
  },

  regions: {
    input: '[data-region="input"]',
    list: '[data-region="list"]',
    invite: '[data-region="invite"]',
    flash: '[data-region="flash"]'
  }
}) {
  // Collection for this view is an enrollment collection, created by the controller.
  initialize(options) {
    // The search collection contains the results of the asynch search
    this.searchCollection = this.collection.getSearchCollection();

    // Can be courses or users, since the same UI elements are used for both.
    this.searchType = this.collection.searchType();

    // The list of current enrollments
    this.enrollmentList = new EnrollmentList({
      collection: this.collection,
      vent: this
    });

    // Error messages and feedback.
    return (this.flashView = new Flash({ vent: this, clearOnAdd: false }));
  }

  showBulkEnrollAndInvite() {
    this.getRegion("invite").reset();
    const enrollmentBulkInput = new EnrollmentBulkInput({
      collection: this.collection,
      vent: this
    });
    this.showChildView("input", enrollmentBulkInput);
    this.listenTo(enrollmentBulkInput, "showSingle", event => {
      return this.showSingle();
    });
    return this.showChildView("input", enrollmentBulkInput);
  }

  showSingleInvite() {
    const singleInvite = new SingleInvite({
      collection: this.collection,
      vent: this,
      useAltTemplate: true
    });
    return this.showChildView("invite", singleInvite);
  }

  showBulk() {
    return this.showBulkEnrollAndInvite();
  }

  showSingle() {
    this.showSingleEnroll();
    if (this.searchType === "user") {
      return this.showSingleInvite();
    }
  }

  showSingleEnroll() {
    const enrollmentInput = new EnrollmentInput({
      collection: this.searchCollection,
      enrollmentCollection: this.collection,
      collectionType: this.searchType,
      vent: this
    });
    this.showChildView("input", enrollmentInput);
    this.listenTo(enrollmentInput, "showBulk", event => {
      return this.showBulk();
    });
    return this.showChildView("input", enrollmentInput);
  }

  onRender() {
    this.showChildView("list", this.enrollmentList);
    this.showChildView("flash", this.flashView);
    this.showSingle();
  }

  onDomRefresh() {
    const data = this.$el.parent().data();
    if (data.hasOwnProperty("label")) {
      this.label = data.label;
      this.getUI("labelContainer").html(this.label);
    }
  }

  serializeData() {
    return {
      label: this.label
    };
  }
}
