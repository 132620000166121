var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<h5 class=\"modal--header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"headerLabel") || (depth0 != null ? lookupProperty(depth0,"headerLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"headerLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":42}}}) : helper)))
    + "</h5>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"headerLabel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + "<p style=\"margin-bottom: 2rem;\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"descriptionLabel") || (depth0 != null ? lookupProperty(depth0,"descriptionLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descriptionLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":52}}}) : helper)))
    + "</p>\n<a href=\"#\" class=\"primary-button confirm-button\" data-behavior=\"confirm\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"confirmLabel") || (depth0 != null ? lookupProperty(depth0,"confirmLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirmLabel","hash":{},"data":data,"loc":{"start":{"line":5,"column":74},"end":{"line":5,"column":90}}}) : helper)))
    + "</a>\n<a href=\"#\" class=\"warning-button cancel-button\" data-behavior=\"dismiss\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"dismissLabel") || (depth0 != null ? lookupProperty(depth0,"dismissLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dismissLabel","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":89}}}) : helper)))
    + "</a>\n";
},"useData":true});