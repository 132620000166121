/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from 'templates/submission/utility/utility.hbs';

import { View, getOption } from "backbone.marionette";

export default class UtilityView extends View.extend({
  template: template
}) {
  initialize(options) {
    this.vent = getOption(this, 'vent');
    return this.courseId = getOption(this, 'courseId');
  }

  serializeData() {
    const data = super.serializeData();
    data.courseId = this.courseId;
    return data;
  }
}
