/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from 'templates/admin/enrollment/empty_users.hbs';

import { View } from "backbone.marionette";

export default class EnrollmentEmptyUsers extends View.extend({
  template: template,
  tagName: 'tr'
}) {
  serializeData() {
    return {
    colspan: 4,
    role: this.options.role
    };
  }

  onDomRefresh() {
    return this.$el.find('th').attr('colspan', this.$el.closest('table').find('thead th').length);
  }
}
