/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { isArray } from "lodash";

export default class AbstractModel extends Backbone.Model.extend({}) {
  toPositiveInt(string) {
    const n = ~~Number(string);
    if (String(n) === string && n >= 0) {
      return n;
    }
    return null;
  }

  addError(errorsObject, property, message) {
    const newErrorsObject = errorsObject;
    if (!newErrorsObject[property] || !isArray(newErrorsObject[property])) {
      newErrorsObject[property] = [];
    }
    return newErrorsObject[property].push(message);
  }
}
