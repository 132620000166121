var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<a class=\"matrix--column-header--link\" href=\"/courses/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"courseId") || (depth0 != null ? lookupProperty(depth0,"courseId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"courseId","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":67}}}) : helper)))
    + "/manage/projects/new\">\n		<span>Create a new project</span>\n	</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	No projects have been created. Check back soon.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreator") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "");
},"useData":true});