/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { isString, isObject, isArray } from "lodash";
import { View } from "backbone.marionette";
import template from "templates/notification/notification_layout.hbs";
import NotificationMessage from "views/notification/notification_message";
import NotificationRegion from "views/notification/notification_region";
import FlashMessageModel from "models/flash_message";

export default class NotificationLayout extends View.extend({
  notificationCounter: 0,
  notificationRegion: null,
  className: "notification",
  template
}) {
  initialize(options) {
    return this.setupEvents();
  }

  onRender() {
    return this.loadServerSideFlashMessages();
  }

  setupEvents() {
    this.listenTo(this.getOption("vent"), "error:add", messageParams => {
      return this.handleIncomingMessages(messageParams);
    });
    this.listenTo(this.getOption("vent"), "notification:show", view => {
      return this.handleIncomingNotification(view);
    });
    this.listenTo(this.getOption("vent"), "notification:empty", () => {
      return this.handleEmptyNotification();
    });
    return this.listenTo(this, "transition:start", (height, timing) => {
      return this.adjustPosition(height, timing);
    });
  }

  marginContainer() {
    return $('[data-behavior="course-subheader"]') || $('.subheader').first();
  }

  adjustPosition(height, timing) {
    let distance;
    const $container = this.marginContainer();
    const marginTop = parseInt(
      ($container.css("marginTop") ? $container.css("marginTop").replace("px", "") : 0),
      10
    );
    const newMargin = marginTop + height;
    $container.animate({ marginTop: `${newMargin}px` }, timing);
    return (distance = height - marginTop);
  }

  handleEmptyNotification() {
    this.removeRegions();
    const $container = this.marginContainer();
    const newMargin = 0;
    return $container.animate({ marginTop: `${newMargin}px` }, 0);
  }

  handleIncomingNotification(view) {
    let name = "notificationRegion";
    if (this.notificationRegion === null) {
      name = this.makeRegion();
      this.notificationRegion = this.getRegion(name);
      this.listenTo(this.notificationRegion, "empty", () => {
        return (this.notificationRegion = null);
      });
    }
    if (!this.notificationRegion.hasView()) {
      return this.showChildView(name, view);
    }
  }

  handleIncomingMessages(params) {
    if (params.hasOwnProperty("clear") && params.clear === true) {
      this.handleEmptyNotification();
    }
    const views = this.messageViewsFromMessageParams(params);
    return views.forEach(view => {
      const regionId = this.makeRegion();
      return this.showChildView(regionId, view);
    });
  }

  getAndIncrementNotificationCounter() {
    const r = this.notificationCounter;
    this.notificationCounter++;
    return r;
  }

  makeRegion() {
    const regionId = `notificationRegion${this.getAndIncrementNotificationCounter()}`;
    const $regionEl = $(
      `<div style="display: none;" class="notification-item" id="${regionId}"></div>`
    );
    this.Dom.getEl(".notification").append($regionEl);
    const region = this.addRegions({
      [`${regionId}`]: { el: `#${regionId}`, regionClass: NotificationRegion }
    });

    this.listenTo(this.getRegion(regionId), "region:expired", () => {
      return this.removeRegion(regionId);
    });
    this.listenTo(
      this.getRegion(regionId),
      "transition:start",
      (height, timing) => {
        return this.trigger("transition:start", height, timing);
      }
    );
    this.listenTo(
      this.getRegion(regionId),
      "transition:complete",
      (height, timing) => {
        return this.trigger("transition:complete", height, timing);
      }
    );
    return regionId;
  }

  messageViewsFromMessageParams(params) {
    let views;
    if (
      !isString(params.msg) &&
      (isObject(params.msg) || isArray(params.msg))
    ) {
      views = this.viewsFromComplexMessageParams(params);
    } else {
      views = [];
      views.push(this.makeOneNotificationView(params));
    }
    return views;
  }

  viewsFromComplexMessageParams(params) {
    let level;
    let lifetime;
    const views = [];
    if (params.level != null) {
      ({ level } = params);
    } else {
      level = "notice";
    }
    if (params.lifetime != null) {
      ({ lifetime } = params);
    } else {
      lifetime = null;
    }
    if (isArray(params.msg)) {
      if (params.msg.length > 0) {
        params.msg.forEach(msg => {
          return views.push(
            this.makeOneNotificationView({
              level,
              msg,
              property: null,
              lifetime
            })
          );
        });
      }
    } else if (isObject(params.msg)) {
      params.msg.forEach((text, property) => {
        if (property === "base") {
          return views.push(
            this.makeOneNotificationView({
              level,
              msg: text,
              property: null,
              lifetime
            })
          );
        }
        return views.push(
          this.makeOneNotificationView({
            level,
            msg: `${property.charAt(0).toUpperCase() +
              property.slice(1)} ${text}`,
            property: null,
            lifetime
          })
        );
      });
    } else {
      views.push(
        this.makeOneNotificationView({
          level,
          msg: params.msg,
          property: null,
          lifetime
        })
      );
    }
    return views;
  }

  makeOneNotificationView(params) {
    let view;
    const model = new FlashMessageModel({
      msg: params.msg,
      level: params.level,
      property: params.property,
      lifetime: params.lifetime
    });
    return (view = new NotificationMessage({
      model
    }));
  }

  loadServerSideFlashMessages() {
    const dataContainer = $("#bootstrap-globalFlash");
    if (dataContainer.length > 0) {
      const div = $("<div></div>");
      div.html(dataContainer.text());
      const text = div.text();
      if (text != null && !/^\s*$/.test(text)) {
        const data = JSON.parse(text);
        if (isArray(data.globalFlash)) {
          return data.globalFlash.forEach(msg => {
            return this.handleIncomingMessages(msg);
          });
        }
      }
    }
  }
}
