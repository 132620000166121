/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { throttle } from "lodash";
import { View } from "backbone.marionette";
import ClosesOnUserAction from "behaviors/closes_on_user_action";

export default class HeaderDrawerView extends View.extend({
  visibleCourses: 5,
  filtered: false,

  behaviors: {
    closesOnUserAction: {
      behaviorClass: ClosesOnUserAction
    }
  },

  ui: {
    courseSelect: '[data-class="course-select"]',
    recentCourseSelect: '[data-class="recent-course-select"]'
  }
}) {
  toggle() {
    if (this.$el.hasClass("drawer-open")) {
      return this.close();
    }
    return this.open();
  }

  open() {
    this.setSpacing();
    $(`[data-drawer-target="${this.drawerTarget}"]`).addClass("drawer-open");
    $(`[data-drawer-target="${this.drawerTarget}"]`).removeClass("drawer-closed");
    $(`[data-drawer-target="${this.drawerTarget}"] a`).addClass("active");
    return this.triggerMethod("opened");
  }

  close() {
    $(`[data-drawer-target="${this.drawerTarget}"]`).addClass("drawer-closed");
    $(`[data-drawer-target="${this.drawerTarget}"]`).removeClass("drawer-open");
    $(`[data-drawer-target="${this.drawerTarget}"] a`).removeClass("active");
    return this.triggerMethod("closed");
  }

  setupListeners() {
    this.getUI("courseSelect").on("change", () => {
      const val = this.getUI("courseSelect").val();
      return window.location.assign(val);
    });
    return this.getUI("recentCourseSelect").on("change", () => {
      const val = this.getUI("recentCourseSelect").val();
      return window.location.assign(val);
    });
  }

  setSpacing() {
    const trigger = $(
      `[data-drawer-target="${this.drawerTarget}"][data-behavior="header-drawer-trigger"]`
    );
    if (trigger.length > 0) {
      const { left } = trigger.offset();
      return this.$el.css({ left: left + "px" });
    }
  }

  initialize(options) {
    this.vent = options.vent;
    const globalChannel = Radio.channel("globalChannel");
    this.drawerTarget = this.$el.data().drawerTarget;
    // Set Spacing anytime the window is resized
    const throttledSpacing = throttle(() => {
      return this.setSpacing();
    }, 50);
    $(window).resize(() => {
      return throttledSpacing();
    });
    this.listenTo(globalChannel, `drawer:${this.drawerTarget}:toggle`, () => {
      return this.toggle();
    });
    this.bindUIElements();

    const chosenOptions = {
      disable_search_threshold: 1000,
      allow_single_deselect: false,
      placeholder_text_single: "Jump to a different course"
    };
    this.getUI("courseSelect").chosen(chosenOptions);

    return this.setupListeners();
  }
}
