var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"cell-description\">"
    + container.escapeExpression(__default(require("../../helpers/truncate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),45,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":65}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"cell-description empty\">Add Description</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-behavior=\"open-modal\">\n    <span class=\"cell-control edit\" data-behavior=\"edit\"><i class=\"edit-icon\"></i></span>\n    <span class=\"cell-control destroy\" data-behavior=\"destroy\"><i class=\"delete-icon\"></i></span>\n    <div class=\"cell-name\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"cellName") : depth0), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});