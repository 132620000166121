/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/page/help_test.hbs";

import { View } from "backbone.marionette";

export default class FlashMessagesItem extends View.extend({
  template,

  events: {
    "mouseenter [data-help]": "onHelpShow",
    "mouseleave [data-help]": "onHelpHide"
  }
}) {
  onHelpShow(event) {
    const target = $(event.currentTarget);
    const orientation = target.attr("data-help-orientation");
    return Radio.channel("app").trigger("help:show", {
      on: target,
      orientation,
      key: target.attr("data-help")
    });
  }

  onHelpHide(event) {
    const target = $(event.currentTarget);
    return Radio.channel("app").trigger("help:hide", {
      on: target,
      key: target.attr("data-help")
    });
  }
}
