/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Item from "views/group/groups_item";
import { CollectionView, getOption } from "backbone.marionette";
import template from "templates/group/groups.hbs";

export default class GroupsView extends CollectionView.extend({
  childView: Item,
  tagName: "thead",
  template,
  childViewContainer: "tr"
}) {
  childViewOptions() {
    return {
      courseId: this.options.courseId,
      vent: this.vent
    };
  }

  initialize(options) {
    this.options = options || {};
    return (this.vent = getOption(this, "vent"));
  }

  onAddChild() {
    return this.vent.trigger("recalculate");
  }

  onRemoveChild() {
    return this.vent.trigger("recalculate");
  }
}
