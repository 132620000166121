import { View } from "backbone.marionette";
import DiscusionPostCollection from "collections/discussion_post_collection";
import DiscussionPostModel from "models/discussion_post";
import FlashMessagesView from "views/flash/flash_messages";

export default class DiscussionView extends View.extend({
  ui: {
    flashContainer: '[data-container="flash"]'
  },
  triggers: {
    'click [data-behavior="post-save"]': "post:save"
  }
}) {
  initialize(options) {
    this.vent = options.vent;
    this.initializeFlash();
  }

  childViewOptions() {
    return {
      allPosts: this.allPosts,
      submission: this.submission
    };
  }

  onRender() {
    this.getUI("flashContainer").append(this.flash.$el);
    this.flash.render();
  }

  initializeFlash() {
    return (this.flash = new FlashMessagesView({
      vent: this,
      clearOnAdd: true
    }));
  }

  onPostSave() {
    let parentId;
    if (this.model != null) {
      parentId = this.model.id;
    } else {
      parentId = null;
    }
    const post = new DiscussionPostModel({
      submission_id: this.submission.id,
      body: this.getUI("bodyInput").val(),
      published: true,
      parent_id: parentId
    });

    this.listenTo(post, "invalid", (model, errors) => {
      return this.trigger("error:add", {
        level: "error",
        lifetime: 5000,
        msg: errors
      });
    });

    post.save(
      {},
      {
        success: p => {
          this.collection.add(p);
          this.allPosts.add(p);
          this.resetInput();
        },
        error: (model, error) => {}
      }
    );
  }

  resetInput() {
    this.triggerMethod("reply:clear");
  }
}
