/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/assets/asset_collection_empty.hbs";

import { View, getOption } from "backbone.marionette";

export default class AssetCollectionEmpty extends View.extend({
  template,
  abilities: {},

  ui: {
    manageLink: '[data-behavior="empty-manage-link"]'
  },

  triggers: {
    "click @ui.manageLink": "show:new"
  }
}) {
  onShowNew() {
    return this.vent.triggerMethod("request:state:manage");
  }

  templateContext() {
    return {
      parentIsProject: getOption(this, "abilities"),
    }
  }

  _assetable() {
    return getOption(this, "assetable");
  }

  _assetableIsProject() {
    if (!this._assetable()) return false;
    return this._assetable().type() === "ProjectModel";
  }

  _assetableIsSubmission() {
    if (!this._assetable()) return false;
    return this._assetable().type() === "SubmissionModel";
  }

  templateContext() {
    return {
      assetableIsProject: this._assetableIsProject(),
      assetableIsSubmission: this._assetableIsSubmission(),
    }
  }

  serializeData() {
    const context = this.abilities;
    context.mediaUploadsClosed =
      this.model.pastDue() &&
      this.model.get("rejects_past_due_media") &&
      window.VocatUserCourseRole === "creator";
    context.allowed_attachment_families = this.model.get(
      "allowed_attachment_families"
    );
    return context;
  }

  initialize(options) {
    this.vent = getOption(this, "vent");
    return (this.abilities = getOption(this, "abilities"));
  }
}
