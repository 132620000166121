var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"body\">\n            <div style=\"margin-top: 0px\" data-region=\"flash\"></div>\n            <form class=\"formgrid new-rubric-form\">\n                <fieldset>\n                    <div class=\"formgrid whole rubric-name\">\n                        <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":50},"end":{"line":8,"column":58}}}) : helper)))
    + "\" data-behavior=\"rubric-name\" placeholder=\"Name your rubric\">\n                    </div>\n                    <div class=\"formgrid whole\">\n                        <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":65}}}) : helper)))
    + "\" data-behavior=\"rubric-desc\" placeholder=\"Enter a brief description of your rubric\">\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"current_user_is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + "                </fieldset>\n            </form>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"formgrid third\">\n                            <span style=\"display: inline-block; margin-right: 5px; vertical-align: middle;\">Is this a system wide rubric?</span>\n                            <div style=\"display: inline-block; vertical-align: middle;\" class=\"switch "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"public") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":102},"end":{"line":16,"column":137}}})) != null ? stack1 : "")
    + "\" data-region=\"rubric-public\">\n                                <input id=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"public") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":91}}})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n                                <label for=\"checkbox\" class=\"switch-label\">\n                                    <span class=\"switch-inner\">\n                                      <span class=\"switch-text switch-active\">Yes</span>\n                                      <span class=\"switch-text switch-inactive\">No</span>\n                                    </span>\n                                </label>\n                            </div>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "switch-checked";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"header-wrapper\">\n            <div style=\"margin: 5px 0\" data-region=\"flash\"></div>\n            <div>\n                <h2 class=\"heading-primary editable-field\" data-trigger=\"edit-attribute\" data-label=\"name\">\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":30}}}) : helper)))
    + "\n                </h2>\n            </div>\n            <div>\n                <p class=\"editable-field\" style=\"font-size: 1.125em; margin-bottom: 10px;\" data-trigger=\"edit-attribute\" data-label=\"description\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":44,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"current_user_is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":41}}}) : helper)))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        Enter a brief description of your rubric\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <span style=\"display: inline-block; margin-right: 5px; vertical-align: middle;\">Is this a system wide rubric?</span>\n                    <div style=\"display: inline-block; vertical-align: middle;\" class=\"switch "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"public") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":94},"end":{"line":50,"column":129}}})) != null ? stack1 : "")
    + "\" data-region=\"rubric-public\">\n                        <input id=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"public") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":45},"end":{"line":51,"column":83}}})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n                        <label for=\"checkbox\" class=\"switch-label\">\n                            <span class=\"switch-inner\">\n                              <span class=\"switch-text switch-active\">Yes</span>\n                              <span class=\"switch-text switch-inactive\">No</span>\n                            </span>\n                        </label>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"new_record") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":62,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"page-section page-section-clear\">\n	<div class=\"body\">\n		<div class=\"rubric-builder\" data-region=\"rubric-builder\"></div>\n\n        <div class=\"rubric-buttons\">\n            <!--\n              <a href=\"#\" data-trigger=\"recalc\" class=\"primary-button\">Recalculate Matrix</a>\n              -->\n            <div style=\"text-align: left; display: inline-block; width: 49%;\">\n                <a href=\"#\" data-trigger=\"save\" class=\"primary-button update-button\">Save rubric</a>\n                <a href=\"#\" data-trigger=\"cancel\" class=\"warning-button cancel-button\">Cancel</a>\n            </div>\n            <div style=\"text-align: right; display: inline-block; width: 49%;\">\n                <a href=\"#\" data-trigger=\"scoring-modal\" class=\"primary-button customize-button\">Customize Scoring</a>\n            </div>\n        </div>\n	</div>\n</div>\n\n\n";
},"useData":true});