import { Behavior } from "backbone.marionette";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default class ExpandableRange extends Behavior.extend({
  options: {
    childrenVisible: false
  },

  triggers: {
    "click @ui.toggleChild": "toggle:child"
  },

  ui: {
    toggleChild: '[data-behavior="toggle-children"]',
    childContainer: '[data-behavior="child-container"]',
    range: '[data-behavior="range"]:first'
  }
}) {
  onRender() {
    if (this.options.childrenVisible === false) {
      return this.getUI("childContainer").hide();
    }
  }

  onToggleChild() {
    if (this.getUI("childContainer").length > 0) {
      if (this.options.childrenVisible) {
        this.getUI("range").removeClass("range-expandable-open");
        this.getUI("childContainer").slideUp(250);
        this.view.trigger("range:closed");
      } else {
        this.getUI("childContainer").slideDown(250);
        this.getUI("range").addClass("range-expandable-open");
        this.view.trigger("range:open");
      }
      return (this.options.childrenVisible = !this.options.childrenVisible);
    }
  }
}
