/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */


import template from 'templates/assets/annotator/annotator.hbs';
import { View } from "backbone.marionette";
import ProgressBarView from 'views/assets/annotator/progress_bar';
import AnnotationInputView from 'views/assets/annotator/annotator_input';
import AnnotationModel from 'models/annotation';
import ModalConfirmView from 'views/modal/modal_confirm';

export default class AnnotatorView extends View.extend({
  template: template,
  hideProgressBar: false,

  regions: {
    progressBar: '[data-behavior="progress-bar"]',
    annotationInput: '[data-region="annotator-input"]'
  },

  ui: {
  }
}) {
  setupListeners() {
    this.listenTo(this.vent, 'request:annotator:save', this.saveAnnotation, this);
    return this.listenTo(this.collection, 'destroy', this.handleAnnotationDestruction, this);
  }

  saveAnnotation(annotation, properties) {
    if (this.submission) {
      annotation.set("submission_id", this.submission.id);
    }
    this.listenToOnce(this.vent, 'announce:status', response => {
      this.listenToOnce(this.vent, 'announce:canvas', canvas => {
        const secondsTimecode = response.playedSeconds;
        properties['canvas'] = canvas;
        properties['published'] = true;
        properties['seconds_timecode'] = secondsTimecode;
        return annotation.save(properties, {
            success: annotation => this.handleAnnotationSaveSuccess(annotation),
            error: (annotation, xhr) => this.handleAnnotationSaveError(annotation, xhr)
          }
        );
      });
      return this.vent.trigger('request:canvas', {});
    });
    return this.vent.trigger('request:status', {});
  }

  handleAnnotationSaveSuccess(annotation) {
    this.collection.add(annotation, {merge: true});
    annotation.activate();
    return this.vent.trigger('request:status', {});
  }

  handleAnnotationSaveError(annotation, xhr) {
    return Radio.channel('app').trigger('error:add', {level: 'error', clear: true, msg: xhr.responseJSON.errors});
  }

  handleAnnotationDestruction(annotation) {
    if (this.getRegion('annotationInput').currentView.model === annotation) {
      return this.vent.trigger('request:annotator:input:stop');
    }
  }

  initialize(options) {
    this.vent = options.vent;
    this.submission = options.submission;
    this.collection = this.model.annotations();
    return this.setupListeners();
  }

  onRender() {
    if (this.model.hasDuration()) {
      this.showChildView('progressBar', new ProgressBarView({model: this.model, vent: this.vent, collection: this.collection}));
    } else {
      $(this.getRegion('progressBar').el).hide();
    }
    this.annotatorInputView = new AnnotationInputView({
      asset: this.model,
      model: new AnnotationModel({asset_id: this.model.id}),
      vent: this.vent,
      collection: this.collection
    });
    return this.showChildView('annotationInput', this.annotatorInputView);
  }
}
