/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/assets/assets_layout.hbs";
import { View, getOption } from "backbone.marionette";
import AssetCollectionView from "views/assets/asset_collection";
import NewAssetView from "views/assets/new_asset";
import NewAssetFooterView from "views/assets/new_asset_footer";
import AssetModel from "models/asset";
import AssetDetail from "views/assets/asset_detail";
import ModalConfirmView from "views/modal/modal_confirm";

export default class AssetsLayout extends View.extend({
  template,
  state: "list",
  canAttach: false,

  ui: {
    assetCollectionHeader: '[data-behavior="asset-collection-header"]',
    detailHeader: '[data-behavior="detail-header"]',
    detailHeaderContent: '[data-behavior="detail-header-content"]',
    newAssetContainer: '[data-behavior="new-asset-container"]',
    manageLink: '[data-behavior="manage-link"]',
    stopManagingLink: '[data-behavior="stop-manage-link"]',
    closeLink: '[data-behavior="close-link"]'
  },

  triggers: {
    "click @ui.stopManagingLink": "request:state:list",
    "click @ui.manageLink": "request:state:manage",
    "click @ui.closeLink": "request:state:list"
  },

  regions: {
    assets: '[data-region="asset-collection"]',
    newAsset: '[data-region="asset-new"]',
    newAssetFooter: '[data-region="asset-new-footer"]'
  }
}) {
  setState(state, assetId = null) {
    if (this.state === "uploading" && state === "detail") {
      Radio.channel("app").trigger("error:add", {
        level: "notice",
        clear: true,
        lifetime: "5000",
        msg: "Please wait for your upload to complete before viewing media."
      });
      return;
    }

    this.state = state;
    switch (state) {
      case "list":
        this.handleStateList();
        break;
      case "firstAdd":
        this.handleStateFirstAdd();
        break;
      case "manage":
        this.handleStateManage();
        break;
      case "detail":
        this.handleStateDetail(assetId);
        break;
      case "uploading":
        this.handleStateUploading();
        break;
      default:
        console.log("Unexpected upload state."); // eslint-disable-line no-console
    }
    return this.trigger("announce:state", this.state);
  }

  handleCollectionAddRemove() {
    if (this.collection.length > 0 && this.state === "firstAdd") {
      this.setState("manage");
    }
    if (this.collection.length === 0 && this.state === "manage") {
      return this.setState("firstAdd");
    }
  }

  handleStateUploading() {
    this.navigateToSubmissionView();
    this.getRegion("newAssetFooter").empty();
    return this._updateUIStateUploading();
  }

  handleStateList() {
    this.navigateToSubmissionView();
    if (this.getRegion("newAssetFooter").currentView != null) {
      this.getRegion("newAssetFooter").$el.fadeOut(200);
    }
    if (this.getRegion("newAsset").currentView != null) {
      this.getRegion("newAsset").$el.fadeOut(200, () => {
        this.getRegion("newAsset").empty();
        return this.showChildView("assets", this._assetCollectionView());
      });
    } else {
      this.showChildView("assets", this._assetCollectionView());
    }

    return this._updateUIStateList();
  }

  handleStateFirstAdd() {
    this.navigateToSubmissionView();
    this.getRegion("assets").empty();
    this.showChildView("newAsset", this._newAssetView());
    this.getRegion("newAsset").$el.fadeIn(200);
    this.getRegion("newAssetFooter").empty();
    return this._updateUIStateFirstAdd();
  }

  navigateToSubmissionView() {
    if (getOption(this, "isInlineInForm")) return;
    return window.Vocat.router.navigate(`${this.submission.detailUrl()}`, false);
  }

  handleStateManage() {
    this.navigateToSubmissionView();
    if (this.getRegion("newAsset").currentView == null) {
      this.showChildView("newAsset", this._newAssetView());
    }
    if (this.getRegion("newAssetFooter").currentView == null) {
      this.showChildView("newAssetFooter", this._newAssetFooterView());
    }
    this.getRegion("newAsset").$el.fadeIn(200);
    this.getRegion("newAssetFooter").$el.fadeIn(200);

    // Workaround for https://github.com/marionettejs/backbone.marionette/issues/3623 I think.
    setTimeout(() => {
      this.showChildView("assets", this._assetCollectionView());
    }, 0);
    return this._updateUIStateManage();
  }

  handleStateDetail(assetId) {
    const asset = new AssetModel({ id: assetId });

    // TODO—do we need to ajax fetch this, if we already have it nested on the submission model?
    const url = `${asset.urlRoot}/${asset.id}/?submission=${this.submission.id}`
    return asset.fetch({
      url: url,
      success: () => {
        let assetableType = this._modelIsProject() ? "Project" : "Submission"
        if (
          asset.get("assetable_type") !== assetableType ||
          asset.get("assetable_id") !== this.model.id
        ) {
          return this.setState("list");
        }
        let label;
        if (asset.get("name")) {
          label = `${asset.get("name")}`;
        } else {
          let family = asset.get("family");
          family = family.charAt(0).toUpperCase() + family.slice(1);
          label = `${family} media`;
        }
        this.getUI("detailHeaderContent").html(label);
        this.getRegion("newAsset").empty();
        this.getRegion("newAssetFooter").empty();
        window.Vocat.router.navigate(
          `${this.submission.detailUrl()}/asset/${assetId}`,
          false
        );
        this.showChildView("assets", this._assetDetail(asset));
        return this._updateUIStateDetail();
      }
    });
  }

  onRequestStateUploading() {
    return this.setState("uploading");
  }

  onRequestStateManage() {
    if (this.collection.length === 0) {
      return this.setState("firstAdd");
    }
    return this.setState("manage");
  }

  onRequestStateDetail() {
    return this.setState("detail");
  }

  onRequestStateList() {
    return this.setState("list");
  }

  renderCollectionView() {
    if (this.getRegion("assets").currentView != null) {
      return this.getRegion("assets").currentView.render();
    }
  }

  _updateUIStateList() {
    this.getUI("detailHeader").hide();
    this.getUI("assetCollectionHeader").show();
    this._hideButton(this.getUI("closeLink"));
    this._hideButton(this.getUI("stopManagingLink"));
    if (this.canAttach) {
      return this._showButton(this.getUI("manageLink"));
    }
    return this._hideButton(this.getUI("manageLink"));
  }

  _updateUIStateUploading() {
    this.getUI("detailHeader").hide();
    this.getUI("assetCollectionHeader").show();
    this._hideButton(this.getUI("closeLink"));
    this._hideButton(this.getUI("stopManagingLink"));
    return this._hideButton(this.getUI("manageLink"));
  }

  _updateUIStateFirstAdd() {
    this.getUI("detailHeader").hide();
    this.getUI("assetCollectionHeader").show();
    this.$el.addClass("empty-list");
    this._hideButton(this.getUI("closeLink"));
    this._showButton(this.getUI("stopManagingLink"));
    return this._hideButton(this.getUI("manageLink"));
  }

  _updateUIStateManage() {
    this.getUI("detailHeader").hide();
    this.getUI("assetCollectionHeader").show();
    this.$el.removeClass("empty-list");
    this._hideButton(this.getUI("closeLink"));
    this._showButton(this.getUI("stopManagingLink"));
    return this._hideButton(this.getUI("manageLink"));
  }

  _updateUIStateDetail() {
    this.getUI("detailHeader").show();
    this.getUI("assetCollectionHeader").hide();
    this._showButton(this.getUI("closeLink"));
    this._hideButton(this.getUI("stopManagingLink"));
    return this._hideButton(this.getUI("manageLink"));
  }

  _modelIsProject() {
    return this.model.type() === "ProjectModel";
  }

  _assetCollectionView() {
    window.debugzd = this
    return new AssetCollectionView({
      collection: this.collection,
      vent: this,
      assetable: this.model,
      project: this._modelIsProject() ? this.model : this.model.project(),
      abilities: this.model.get("abilities")
    });
  }

  _newAssetView() {
    return new NewAssetView({
      collection: this.collection,
      assetable: this.model,
      model: this._modelIsProject() ? this.model : this.model.project(),
      vent: this
    });
  }

  _newAssetFooterView() {
    return new NewAssetFooterView({ vent: this });
  }

  _assetDetail(asset) {
    return new AssetDetail({
      courseId: this.courseId,
      model: asset,
      assetable: this.model,
      submission: this.submission,
      context: "submission"
    });
  }

  _hideButton(button) {
    return button.css({ display: "none" });
  }

  _showButton(button) {
    return button.css({ display: "inline-block" });
  }

  setupListeners() {
    this.listenTo(this, "asset:detail", args => {
      return this.setState("detail", args.asset);
    });
    this.listenTo(this, "request:state", args => {
      return this.trigger("announce:state", this.state);
    });
    this.listenTo(this.collection, "reset", e => {
      return this.renderCollectionView();
    });
    return this.listenTo(this.collection, "add remove", e => {
      return this.handleCollectionAddRemove();
    });
  }

  onRender() {
    return this.setState("list");
  }

  templateContext() {
    return {
      isInlineInForm: getOption(this, "isInlineInForm"),
      assetableIsProject: this._modelIsProject(),
      assetableIsSubmission: !this._modelIsProject(),
    }
  }

  // @model is a submission model.
  initialize(options) {
    this.courseMapContext = getOption(this, "courseMapContext");
    this.submission = getOption(this, "submission");
    this.courseId = getOption(this, "courseId");
    const abilities = this.model.get("abilities");
    this.canAttach = abilities.can_attach && !getOption(this, "readOnly");
    this.setupListeners();
    if (options.initialAsset) {
      return this.setState("detail", options.initialAsset);
    }
  }
}
