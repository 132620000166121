/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */


import template from 'templates/assets/annotations/annotations.hbs';
import { CollectionView, getOption } from "backbone.marionette";
import ItemView from 'views/assets/annotations/annotations_item';
import EmptyView from 'views/assets/annotations/annotations_item_empty';

export default class AnnotationsView extends CollectionView.extend({
  template: template,
  scrollLocked: false,
  highlighted: null,
  emptyView: EmptyView,
  className: 'annotations',

  triggers: {
    'click [data-behavior="show-all"]': 'show:all',
    'click [data-behavior="show-auto"]': 'show:auto',
    'click @ui.enableEdit': 'edit:enable',
    'click @ui.disableEdit': 'edit:disable'
  },

  childView: ItemView,
  childViewContainer: '[data-behavior="annotations-container"]',

  ui: {
    count: '[data-behavior="annotation-count"]',
    annotationsContainer: '[data-behavior="annotations-container"]',
    anchor: '[data-behavior="anchor"]',
    scrollParent: '[data-behavior="scroll-parent"]'
  },

  childViewEvents: {
    activated(view) {
      this.handleChildViewActivation(view);
    },
    beforeRemove() {
      this.lockScrolling();
    },
    afterRemove() {
      this.unlockScrolling();
    }

  }

}) {
  childViewOptions(model, index) {
    return {
    model,
    vent: this.vent,
    assetHasDuration: this.model.hasDuration(),
    errorVent: this.vent
    };
  }

  onBeforeDestroy() {
    $(window).off("resize");
    $(window).off("scroll");
    return true;
  }

  unFade() {
    return this.getUI('scrollParent').removeClass('annotations-faded');
  }

  passTimeToCollection(data) {
    return this.collection.setActive(data.playedSeconds);
  }

  setupListeners() {
    this.listenTo(this.vent, 'announce:time:update', this.unFade, this);
    this.listenTo(this.collection, 'model:activated', this.displayActive, this);
    return this.listenTo(this.vent, 'announce:time:update', this.passTimeToCollection, this);
  }

  initialize(options) {
    this.vent = getOption(this, 'vent');
    this.collection = this.model.annotations();
    return this.setupListeners();
  }

  handleChildViewActivation(view) {
    return this.displayActive();
  }

  lockScrolling() {
    return this.scrollLocked = true;
  }

  unlockScrolling() {
    return this.scrollLocked = false;
  }

  isScrolledIntoView($el) {
    const containerHeight = this.getUI('scrollParent').outerHeight();
    const elTop = $el.position().top;
    const elBottom = elTop + $el.outerHeight();
    return (elTop > 0) && (elBottom <= containerHeight);
  }

  scrollToModel(speed, model) {
    if (speed == null) { speed = 250; }
    const view = this.children.findByModel(model);
    if (!this.isScrolledIntoView(view.$el)) {
      const targetPosition = view.$el.position().top;
      return this.getUI('scrollParent').animate({scrollTop: this.getUI('scrollParent').scrollTop() + targetPosition}, speed, 'swing');
    }
  }

  displayActive(speed) {
    if (speed == null) { speed = 250; }
    let activeModel = this.collection.findWhere({active: true});
    if (!this.model.hasDuration()) { return; }
    if (this.scrollLocked === false) {
      if (!activeModel) {
        activeModel = this.collection.last();
      }
      if (activeModel) {
        this.vent.trigger('request:annotation:show', activeModel);
        this.getUI('scrollParent').removeClass('annotations-faded');
        return this.scrollToModel(speed, activeModel);
      }
    }
  }

  // Triggered by child childView; echoed up the event chain to the global event
  onPlayerSeek(data) {
    return this.vent.trigger('player:seek', data);
  }

  serializeData() {
    return {
    hasDuration: this.model.hasDuration(),
    showAnnotations: this.model.showAnnotations(),
    count: this.collection.length,
    countNotOne: this.collection.length !== 1
    };
  }

  // See https://github.com/marionettejs/backbone.marionette/wiki/Adding-support-for-sorted-collections
  appendHtml(collectionView, childView, index) {
    let childrenContainer;
    if (collectionView.childViewContainer) {
      childrenContainer = collectionView.$(collectionView.childViewContainer);
    } else {
      childrenContainer = collectionView.$el;
    }
    const children = childrenContainer.children();
    if (children.size() <= index) {
      return childrenContainer.append(childView.el);
    } else {
      return childrenContainer.children().eq(index).before(childView.el);
    }
  }

  onDomRefresh() {
    return this.matchAnnotationsHeightToPlayerHeight();
  }

  matchAnnotationsHeightToPlayerHeight() {
    return this.getUI('scrollParent').outerHeight($('[data-behavior="player-column"]').outerHeight());
  }

  onDestroy() {
    return $(window).off('scroll', this.scrollHandler);
  }
}
