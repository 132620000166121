/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default class VisitModel extends Backbone.Model.extend({}) {
  url() {
    return "/api/v1/visits";
  }
}
