import videojs from "video.js";

const Plugin = videojs.getPlugin('plugin');
const Button = videojs.getComponent('Button');

class RewindComponent extends Button {
  constructor(player, settings) {
    super(player, settings);
    this.player = player;
  }

  buildCSSClass() {
    return super.buildCSSClass(...arguments) + "vjs-rewind-button";
  }

  handleClick() {
    super.handleClick(...arguments);
    return this.player.currentTime(this.player.currentTime() - 10);
  }
};

class VjsRewindPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.player.controlBar.addChild(new RewindComponent(this.player, options));
  }
}

videojs.registerPlugin('rewind', VjsRewindPlugin);

export default RewindComponent;
