/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { map } from "lodash";
import { View } from "backbone.marionette";
require("jquery-ui");
require("ajax_chosen");
import ConfirmInvite from 'views/admin/enrollment/confirm_invite';
import template from 'templates/admin/enrollment/bulk_input.hbs';

export default class EnrollmentBulkInput extends View.extend({
  ui: {
    showSingle: '[data-behavior="show-single"]',
    bulkInput: '[data-behavior="bulk-input"]',
    submit: '[data-behavior="submit"]'
  },

  triggers: {
    'click [data-behavior="show-single"]': 'showSingle',
    'click [data-behavior="submit"]': 'submit'
  },

  regions: {
    confirmInvite: '[data-region="confirm-invite"]'
  },

  template: template
}) {
  initialize(options) {
    return this.vent = options.vent;
  }

  onSubmit() {
    let endpoint;
    this.getUI('submit').addClass('loading');
    const contacts = this.getUI('bulkInput').val();
    if (this.collection.searchType() === 'user') {
      endpoint = this.collection.bulkUrl();
    }
    return $.ajax(endpoint, {
      type: 'POST',
      dataType: 'json',
      data: {contacts, invite: false},
      success: (data, textStatus, jqXHR) => {
        return this.handleSubmitSuccess(jqXHR.responseJSON);
      },
      error: (jqXHR, textStatus, error) => {
        this.getUI('submit').removeClass('loading');
        return Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 5000, msg: jqXHR.responseJSON.errors});
      }
    });
  }

  showInviteMustConfirm(contacts) {
    return Radio.channel('app').trigger('notification:show',
      new ConfirmInvite({collection: this.collection, contacts, vent: this.vent}));
  }

  handleSubmitSuccess(response) {
    this.getUI('submit').removeClass('loading');
    const confirm = [];
    const failures = [];
    const successes = [];
    const strings = [];
    response.forEach(contact => {
      if (contact.success === true) {
        return successes.push(contact);
      } else if ((contact.success === false) && (contact.reason === 'must_confirm')) {
        strings.push(contact.string);
        return confirm.push(contact);
      } else {
        strings.push(contact.string);
        return failures.push(contact);
      }
    });
    Radio.channel('app').trigger('error:add', {level: 'notice', lifetime: 10000, msg: map(successes, 'message')});
    Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 10000, msg: map(failures, 'message')});

    const inputValue = strings.join("\n");
    this.getUI('bulkInput').val(inputValue);

    if (confirm.length > 0) {
      this.showInviteMustConfirm(confirm);
    }

    return this.collection.fetch();
  }
}
