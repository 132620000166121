/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default function(theArray, theString, options) {
  if (!Array.isArray(theArray)) {
    return options.hash.showIfNull ? options.fn(this) : options.inverse(this);
  }
  if (theArray.indexOf(theString) !== -1) {
    return options.fn(this);
  }
  return options.inverse(this);
}
