/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default function(cells, field, range, crop) {
  let modifiedCrop = crop;
  if (modifiedCrop == null) {
    modifiedCrop = 0;
  }
  const cell = cells.find(c => c.field === field.id && c.range === range.id);
  const desc = cell.description;
  if (modifiedCrop > 0) {
    return (
      desc.substr(0, modifiedCrop - 1) +
      (desc.length > modifiedCrop ? "..." : "")
    );
  }
  return desc;
}
