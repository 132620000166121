var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<figure class=\"frame-attachment\">\n	<div class=\"attachment-placeholder\">\n		<span class=\"attachment-placeholder--message\">Video not yet uploaded</span>\n	</div>\n</figure><!-- removes whitespace between columns\n-->\n<div class=\"frame-summary\">\n	<h2 class=\"frame-summary--title\"><a\n			href=\"/courses/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_id") : stack1), depth0))
    + "/creator/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"current_user_id") : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>\n	</h2>\n\n	<h3 class=\"frame-summary--course\"><a\n			href=\"/courses/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_id") : stack1), depth0))
    + "/creator/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"current_user_id") : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_department") : stack1), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_number") : stack1), depth0))
    + " </strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_name") : stack1), depth0))
    + "\n		[Section "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_section") : stack1), depth0))
    + "]</a></h3>\n"
    + ((stack1 = container.invokePartial(require("../partials/scoreSummary.hbs"),depth0,{"name":"../partials/scoreSummary","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	<a class=\"frame-summary--link\"\n	   href=\"/courses/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"course_id") : stack1), depth0))
    + "/creator/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"current_user_id") : stack1), depth0))
    + "/project/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"project") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Submit a\n		Video</a>\n</div>\n";
},"usePartial":true,"useData":true});