var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "two-thirds";
},"3":function(container,depth0,helpers,partials,data) {
    return "whole";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"embed-watermark\">\n				<img alt=\"VOCAT\" src=\"/static_assets/images/logo.png\" />\n			</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authorized_user") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":58,"column":10}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"asset-overlay-trigger\">\n				<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-info-circle\"\n					viewBox=\"0 0 16 16\">\n					<path d=\"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z\" />\n					<path\n						d=\"m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z\" />\n				</svg>\n			</div>\n			<div class=\"asset-overlay\">\n				<div>\n					<table>\n						<tr>\n							<td><b>Organization:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"organization_name") || (depth0 != null ? lookupProperty(depth0,"organization_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization_name","hash":{},"data":data,"loc":{"start":{"line":25,"column":11},"end":{"line":25,"column":32}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Course:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"course_name") || (depth0 != null ? lookupProperty(depth0,"course_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"course_name","hash":{},"data":data,"loc":{"start":{"line":29,"column":11},"end":{"line":29,"column":26}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Project:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"project_name") || (depth0 != null ? lookupProperty(depth0,"project_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"project_name","hash":{},"data":data,"loc":{"start":{"line":33,"column":11},"end":{"line":33,"column":27}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Asset name:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":37,"column":11},"end":{"line":37,"column":19}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Uploaded by:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"creator_name") || (depth0 != null ? lookupProperty(depth0,"creator_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creator_name","hash":{},"data":data,"loc":{"start":{"line":41,"column":11},"end":{"line":41,"column":27}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Uploaded at:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"created_at") || (depth0 != null ? lookupProperty(depth0,"created_at") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"created_at","hash":{},"data":data,"loc":{"start":{"line":45,"column":11},"end":{"line":45,"column":25}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Asset type:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"file_info") || (depth0 != null ? lookupProperty(depth0,"file_info") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_info","hash":{},"data":data,"loc":{"start":{"line":49,"column":11},"end":{"line":49,"column":24}}}) : helper)))
    + "</td>\n						</tr>\n						<tr>\n							<td><b>Vocat Link:</b></td>\n							<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":53,"column":11},"end":{"line":53,"column":18}}}) : helper)))
    + "</td>\n						</tr>\n					</table>\n				</div>\n			</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "		<button data-behavior=\"show-embed-help\" class=\"primary-button\" style=\"float: right;\">\n			<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-pip\" viewBox=\"0 0 16 16\">\n			  <path d=\"M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z\"/>\n			  <path d=\"M8 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-3z\"/>\n			</svg>\n		</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div data-behavior=\"annotations-column\"\n		 class=\"page-section page-section-one-third col-two "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasDuration") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":54},"end":{"line":73,"column":117}}})) != null ? stack1 : "")
    + "\"\n		 data-region=\"annotations\">\n	</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "annotations-column-scrollable";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-section-wrapper asset-detail-layout asset-detail-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"family") || (depth0 != null ? lookupProperty(depth0,"family") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"family","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":76}}}) : helper)))
    + "\">\n	<div data-behavior=\"player-column\" class=\"page-section page-section-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAnnotations") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":69},"end":{"line":2,"column":122}}})) != null ? stack1 : "")
    + " col-one\">\n		<div class=\"asset-player-container\" style=\"position: relative; overflow: hidden;\">\n			<div data-region=\"annotations-stage\"></div>\n			<div data-region=\"annotator-canvas\"></div>\n			<div class=\"asset-player\" data-region=\"player\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"embedded") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":59,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n		<div data-region=\"annotator\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmbedDialog") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":2},"end":{"line":69,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAnnotations") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":1},"end":{"line":76,"column":8}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});