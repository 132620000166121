/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from 'templates/course/manage/projects/project_row.hbs';
import { View } from "backbone.marionette";
import DropdownView from 'views/layout/dropdown';
import ModalConfirmView from 'views/modal/modal_confirm';

export default class ProjectRowView extends View.extend({
  template: template,
  tagName: 'tr',

  events: {
    "drop": "onDrop"
  },

  ui: {
    "dropdowns": '[data-behavior="dropdown"]'
  },

  triggers: {
    'click [data-behavior="destroy"]': 'click:destroy'
  }
}) {
  onConfirmDestroy() {
    return this.model.destroy({
      success: () => {
        Radio.channel('app').trigger('error:clear');
        Radio.channel('app').trigger('error:add', {level: 'notice', lifetime: '5000', msg: 'The project was successfully deleted.'});
      },
      error: () => {
        Radio.channel('app').trigger('error:clear');
        Radio.channel('app').trigger('error:add', {level: 'notice', msg: xhr.responseJSON.errors});
      }
    });
  }

  onClickDestroy() {
    return Radio.channel('app').trigger('modal:open', new ModalConfirmView({
      model: this.model,
      vent: this,
      headerLabel: 'Are You Sure?',
      descriptionLabel: 'Deleting this project will also delete all of its associated submissions and evaluations. Are you sure you want to do this?',
      confirmEvent: 'confirm:destroy',
      dismissEvent: 'dismiss:destroy'
    }));
  }

  onDrop(e, i) {
    return this.trigger("updatesort", [this.model, i]);
  }

  onRender() {
    return this.getUI('dropdowns').each((index, el) => new DropdownView({el, vent: window.Vocat.vent, allowAdjustment: false}));
  }

  initialize(options) {}
}
