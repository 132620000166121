var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"formgrid\">\n	<div class=\"formgrid whole\">\n		<p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":3,"column":5},"end":{"line":3,"column":14}}}) : helper)))
    + "</p>\n		<input data-property=\"input\" name=\"input\" type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":4,"column":63},"end":{"line":4,"column":72}}}) : helper)))
    + "\"/>\n\n		<div class=\"alert alert-error\" data-behavior=\"error-container\" style=\"display: none;\"></div>\n	</div>\n	<div class=\"formgrid whole\">\n		<button class=\"primary-button "
    + alias4(((helper = (helper = lookupProperty(helpers,"saveClasses") || (depth0 != null ? lookupProperty(depth0,"saveClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveClasses","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":47}}}) : helper)))
    + "\" data-behavior=\"model-save\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"saveLabel") || (depth0 != null ? lookupProperty(depth0,"saveLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveLabel","hash":{},"data":data,"loc":{"start":{"line":9,"column":76},"end":{"line":9,"column":89}}}) : helper)))
    + "</button>\n		<button class=\"warning-button cancel-button\" data-behavior=\"cancel\">Cancel</button>\n	</div>\n</form>\n";
},"useData":true});