/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import {} from "jquery-ujs";

import { View } from "backbone.marionette";

export default class FileInputView extends View.extend({
  ui: {
    trigger: '[data-behavior="file-input-trigger"]',
    field: '[data-behavior="file-input-field"]',
    mask: '[data-behavior="file-input-mask"]'
  },

  triggers: {
    "click @ui.trigger": "trigger:click",
    "change @ui.field": "mask:update"
  }
}) {
  onMaskUpdate() {
    const val = $(this.getUI("field")).val();
    const newVal = val.replace(/^C:\\fakepath\\/i, "");
    return $(this.getUI("mask")).val(newVal);
  }

  onTriggerClick() {
    return $(this.getUI("field")).click();
  }
}
