/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { debounce } from "lodash";
import { View } from "backbone.marionette";

const Waypoints = require("waypoints");
const WaypointsSticky = require("waypoints_sticky");

export default class AbstractMatrix extends View.extend({
  minWidth: 175,
  maxWidth: 300,
  memoizeHashCount: 0,
  position: 0,
  counter: 0,
  stickyHeader: false,

  locks: {
    forward: false
  },

  ui: {
    sliderWrapper: '[data-behavior="matrix-slider"]',
    sliderLeft: '[data-behavior="matrix-slider-left"]',
    sliderRight: '[data-behavior="matrix-slider-right"]'
  },

  triggers: {
    'click [data-behavior="matrix-slider-left"]': "slider:left",
    'click [data-behavior="matrix-slider-right"]': "slider:right"
  }
}) {
  adjustToCurrentPosition() {
    return this.recalculateMatrix();
  }

  parentOnShow() {
    this.recalculateMatrix();
    if (this.stickyHeader) {
      this._initializeStickyHeader();
    }
    this.listenTo(
      this,
      "recalculate",
      debounce(
        () => {
          return this.recalculateMatrix();
        },
        150,
        { leading: true }
      )
    );

    $(window).resize(function() {
      if (this.resizeTo) {
        clearTimeout(this.resizeTo);
        return (this.resizeTo = setTimeout(function() {
          return $(this).trigger("resize_end");
        }));
      }
    });
    return $(window).bind("resize_end", () => {
      return this.recalculateMatrix();
    });
  }

  recalculateMatrix() {
    this._setColumnWidths();
    this._setRowHeights();
    this._positionSliderControls();
    return this._updateSliderControls();
  }

  slideToEnd() {
    this.locks.forward = true;
    this._slideTo(this._columnCount());
    return this._updateSliderControls();
  }

  onSliderLeft() {
    return this._slide("backward");
  }

  onSliderRight() {
    return this._slide("forward");
  }

  onBeforeClose() {
    $(window).off("resize");
    return true;
  }

  getVisibleProjects() {
    return this._getVisibleProjects();
  }

  onMatrixScroll() {
    // Update the position value
    let position = this.position;
    const left = this._currentLeft();
    const columnWidth = this._getColumnWidth();
    position = Math.floor(left / columnWidth);
    this.position = position;
    // Update slider controls
    this._positionSliderControls();
    return this._updateSliderControls();
  }

  _getBrowserWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  _getColHeadersTable() {
    return this.$el.find('[data-behavior="col-headers"]');
  }

  _getActor() {
    return this.$el.find('[data-behavior="matrix-actor"]');
  }

  _getStage() {
    return this.$el.find('[data-behavior="matrix-stage"]');
  }

  _getWrapper() {
    return this.$el.find('[class="matrix-wrapper"]');
  }

  _getColHeaderCells() {
    return this.$el.find('[data-behavior="col-headers"] th');
  }

  _getFirstRowCells() {
    return this.$el.find('[data-behavior="matrix-actor"] tr:first-child td');
  }

  _getRowHeaders() {
    return this.$el.find("[data-vertical-headers] th");
  }

  _getActorRows() {
    return this._getActor().find("tr");
  }

  _getWidthCheckCells() {
    return this.$el.find(
      '[data-behavior="col-headers"] tr:first-child th, [data-behavior="matrix-actor"] tr:first-child td'
    );
  }

  _getHandleWidth() {
    let show;
    if (this.getUI("sliderLeft").is(":visible")) {
      show = true;
    } else {
      show = false;
    }
    this.getUI("sliderLeft").show();
    const w = Math.floor(this.getUI("sliderLeft").outerWidth());
    if (show === false) {
      this.getUI("sliderLeft").hide();
    }
    return w;
  }

  _setRowHeights() {
    const spacerHeight = this._getNaturalSpacerHeight();
    this._setSpacerHeight();
    const actorRows = this._getActorRows();
    return this._getRowHeaders().each((index, el) => {
      const $header = $(el);
      const $row = $(actorRows[index]).find("td");
      const headerHeight = $header.outerHeight();
      const rowHeight = $row.outerHeight();
      if (headerHeight > rowHeight) {
        return $row.outerHeight(headerHeight);
      }
      if (rowHeight > headerHeight) {
        return $header.outerHeight(rowHeight);
      }
    });
  }

  _getMaxNaturalColumnWidth() {
    const cells = this._getWidthCheckCells();

    let maxWidth = 0;
    cells.each((i, cell) => {
      const $cell = $(cell);
      const style = $cell.attr("style");
      $cell.attr({ style: `max-width: ${this.maxWidth}px` });
      const w = $cell.outerWidth();
      $cell.attr({ style });
      if (w > maxWidth) {
        return (maxWidth = w);
      }
    });
    return maxWidth;
  }

  _getColumnWidth() {
    let maxWidth = this._getMaxNaturalColumnWidth();
    const browserWidth = this._getBrowserWidth();
    // make more roomy for desktops, condensed for mobile
    if (browserWidth > 960 && maxWidth < 300) {
      maxWidth = 300;
    } else if (browserWidth < 446) {
      maxWidth = 175;
    }
    if (maxWidth > this.minWidth) {
      return maxWidth;
    }
    return this.minWidth;
  }

  _getWrapperWidth() {
    return this._getWrapper().outerWidth();
  }

  _getActorWidth() {
    return this._getActor().outerWidth();
  }

  _getRowHeaderWidth() {
    return this._getRowHeaders().outerWidth();
  }

  _columnCount() {
    const bodyCols = this._getFirstRowCells().length;
    const headerCols = this._getColHeaderCells().length;
    return Math.max(bodyCols, headerCols);
  }

  _getAdjustedColumnWidth() {
    const width = this._getColumnWidth();
    const space = this._visibleWidth();
    const colsAtMin = Math.floor(space / width);
    let adjustedWidth = space / colsAtMin;
    adjustedWidth =
      adjustedWidth < this.maxWidth ? adjustedWidth : this.maxWidth;

    return adjustedWidth;
  }

  _visibleWidth() {
    return this._getStage().outerWidth() - this._getHandleWidth();
  }

  _removeWidthConstraints() {
    this._getColHeadersTable().outerWidth(300);
    return this._getActor().outerWidth(300);
  }

  _setColumnWidths() {
    this._removeWidthConstraints();
    let totalWidth = 0;
    const width = this._getAdjustedColumnWidth();
    this._getColHeaderCells().each(function(i, cell) {
      totalWidth += width;
      return $(cell).outerWidth(width);
    });
    this._getColHeadersTable().outerWidth(totalWidth);
    this._getFirstRowCells().each((i, cell) => $(cell).outerWidth(width));
    // Set outer width of each actor
    this._getActor().outerWidth(totalWidth);
    this._getStage().outerWidth(totalWidth);
    return null;
  }

  _getNaturalSpacerHeight() {
    this.$el.find("[data-match-height-target]").css({ height: "auto" });
    return this.$el.find("[data-match-height-target]").outerHeight();
  }

  _setSpacerHeight() {
    const minHeight = this._getNaturalSpacerHeight();
    // Work around for chrome rendering bug when height of th in table is adjusted.
    const targetHeight = this.$el
      .find("[data-match-height-source]")
      .outerHeight();
    if (targetHeight > minHeight) {
      return this.$el
        .find("[data-match-height-target]")
        .outerHeight(targetHeight)
        .hide()
        .show();
    }
    return this.$el
      .find("[data-match-height-source]")
      .outerHeight(minHeight)
      .hide()
      .show();
  }

  _positionSliderControls() {
    // Move the right and left buttons if scrollbar is visible
    const $wrapper = this.getUI("sliderWrapper");
    let scrollbarWidth = 0;
    let scrollbarHeight = 0;

    if ($wrapper && $wrapper[0]) {
      scrollbarWidth = $wrapper[0].offsetWidth - $wrapper[0].clientWidth;
      scrollbarHeight = $wrapper[0].offsetHeight - $wrapper[0].clientHeight;
    }

    if (scrollbarWidth > 0) {
      this.getUI("sliderRight").css({
        right: scrollbarWidth,
        height: `calc(100% - ${288 + scrollbarHeight}px)`
      });
      this.getUI("sliderLeft").css({
        height: `calc(100% - ${288 + scrollbarHeight}px)`
      });
    }
  }

  _updateSliderControls() {
    if (this._canSlideBackwardFrom()) {
      this.getUI("sliderLeft").show();
    } else {
      this.getUI("sliderLeft").hide();
    }
    if (this._canSlideForwardFrom() && this.locks.forward === false) {
      this.getUI("sliderRight").show();
    } else {
      this.getUI("sliderRight").hide();
    }
    return this._positionSliderLeft();
  }

  _canSlideForwardFrom(left = null) {
    let modifiedLeft = left;
    if (modifiedLeft === null) {
      modifiedLeft = this._currentLeft();
    }
    const outerWidth = this._getWrapperWidth();
    const width = this._getActorWidth();
    const leftOffset = this._getRowHeaderWidth();
    const r = modifiedLeft + outerWidth - leftOffset;
    return r < width;
  }

  _canSlideBackwardFrom(left = null) {
    let modifiedLeft = left;
    if (modifiedLeft === null) {
      modifiedLeft = this._currentLeft();
    }
    return modifiedLeft > 0;
  }

  /** Returns current scroll left position of the wrapper */
  _currentLeft() {
    let l = this._getWrapper().scrollLeft();
    l = parseInt(l, 10);
    return l;
  }

  _hiddenWidth() {
    return Math.floor(
      this._getActorWidth() -
        this._getWrapperWidth() -
        this._getRowHeaderWidth()
    );
  }

  _positionSliderLeft() {
    const w = this._getRowHeaders().outerWidth();
    return this.getUI("sliderLeft").css("left", w);
  }

  _slide(direction) {
    const globalChannel = Radio.channel("globalChannel");
    globalChannel.trigger("user:action");
    let position = this.position;
    if (direction === "forward" && this._canSlideForwardFrom()) {
      position += 1;
    }
    if (direction === "backward" && this._canSlideBackwardFrom()) {
      position -= 1;
    }
    return this._slideTo(position);
  }

  _checkAndAdjustPosition(position) {
    let modifiedPosition = position;
    if (modifiedPosition > this._maxAttainablePosition()) {
      modifiedPosition = this._maxAttainablePosition();
    }
    if (modifiedPosition < 0) {
      modifiedPosition = 0;
    }
    return modifiedPosition;
  }

  _visibleColumns() {
    let out = Math.floor(this._visibleWidth() / this._getAdjustedColumnWidth());
    if (out === 0) {
      out = 1;
    }
    return out;
  }

  _maxAttainablePosition() {
    return this._columnCount() - this._visibleColumns();
  }

  _slideTo(position) {
    this.position = position;
    return this._animate(this._targetForPosition(this.position), () => {
      this.locks.forward = false;
      return this._updateSliderControls();
    });
  }

  _getCurrentColumnWidth(column) {
    return $(this._getColHeaderCells().get(column)).outerWidth();
  }

  _targetForPosition(position) {
    const columnWidth = this._getCurrentColumnWidth(position);
    let target = position * columnWidth * -1;
    target = Math.ceil(target);
    return target;
  }

  _animate(target, duration) {
    let modifiedDuration = duration;
    if (modifiedDuration == null) {
      modifiedDuration = 250;
    }
    const scrollLeft = target * -1;
    this._getWrapper().animate({ scrollLeft }, modifiedDuration);
  }

  _initializeStickyHeader() {
    const $el = this.$el.find('[data-class="sticky-header"]');
    return $el.waypoint("sticky", {
      offset: $(".page-header").outerHeight(),
      handler(direction) {
        const $container = $(this);
        const child = $container.children(":first");
        if (direction === "down") {
          child.outerWidth(child.outerWidth());
          $container
            .find('[data-behavior="dropdown-options"]')
            .each((index, el) => $(el).trigger("stuck"));
        }
        if (direction === "up") {
          return child.css({ width: "auto" });
        }
      }
    });
  }

  /** Returns visible project headers */
  _getVisibleProjects() {
    const projectEls = this.$el.find('[data-behavior="navigate-project"]');
    const projectIds = [];

    projectEls.each((i, el) => {
      const isVisible = this._isScrolledIntoView(el);
      if (isVisible && el.dataset.project) {
        projectIds.push(el.dataset.project);
      }
    });

    return projectIds;
  }

  _isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemLeft = rect.left;
    const elemRight = rect.right;

    // Only completely visible elements return true:
    return elemLeft >= 0 && elemRight <= window.innerWidth;
  }

  _recalculatePosition() {
    let position = this.position;
    const left = this._currentLeft();
    const columnWidth = this._getColumnWidth();
    position = Math.floor(left / columnWidth);
    this.position = position;
  }
}
