var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert notice\">\n\n	<p>There are no students enrolled in this course.</p>\n\n	<p class=\"small\">Before you can create groups, you need to add some students to this course. Click the button below\n		to enroll students.\n	</p>\n\n	<p class=\"small\">\n		<a href=\"/courses/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"courseId") || (depth0 != null ? lookupProperty(depth0,"courseId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"courseId","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":32}}}) : helper)))
    + "/manage/enrollment\" class=\"primary-button new-button\">Manage Course Enrollment</a>\n	</p>\n\n</div>\n\n";
},"useData":true});