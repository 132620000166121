/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { isNaN } from "lodash";

import VocatController from "controllers/vocat_controller";
import EnrollmentCollection from "collections/enrollment_collection";
import EnrollmentLayout from "views/admin/enrollment_layout";

export default class AdminController extends VocatController.extend({
  collections: {}
}) {
  evaluatorEnrollment(courseId) {
    if (!isNaN(parseInt(courseId, 10))) {
      const view = new EnrollmentLayout({
        collection: new EnrollmentCollection([], {
          scope: { course: courseId, role: "evaluator" }
        })
      });
      return window.Vocat.getRegion().show(view);
    }
  }

  assistantEnrollment(courseId) {
    if (!isNaN(parseInt(courseId, 10))) {
      const view = new EnrollmentLayout({
        collection: new EnrollmentCollection([], {
          scope: { course: courseId, role: "assistant" }
        })
      });
      return window.Vocat.getRegion().show(view);
    }
  }

  creatorEnrollment(courseId) {
    if (!isNaN(parseInt(courseId, 10))) {
      const view = new EnrollmentLayout({
        collection: new EnrollmentCollection([], {
          scope: { course: courseId, role: "creator" }
        })
      });
      return window.Vocat.getRegion().show(view);
    }
  }

  courseEnrollment(userId) {
    if (!isNaN(parseInt(userId, 10))) {
      const view = new EnrollmentLayout({
        collection: new EnrollmentCollection([], { scope: { user: userId } })
      });
      return window.Vocat.getRegion().show(view);
    }
  }
}
