/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { bindAll } from "lodash";
import { View } from "backbone.marionette";
import template from "templates/modal/modal_group_membership.hbs";
import GroupModel from "models/group";

export default class ModalGroupMembershipView extends View.extend({
  template,

  triggers: {
    'click [data-behavior="dismiss"]': "click:dismiss"
  }
}) {
  onKeyUp(e) {
    const code = e.keyCode != null ? e.keyCode : e.which;
    if (code === 27) {
      return this.onClickDismiss();
    }
  }

  onClickDismiss() {
    return Radio.channel("app").trigger("modal:close");
  }

  onDestroy() {
    return $(window).off("keyup", this.onKeyUp);
  }

  initialize(options) {
    bindAll(this, "onKeyUp");
    $(window).on("keyup", this.onKeyUp);
    const { groupId } = options;
    this.model = new GroupModel({ id: groupId });
    return this.model.fetch({
      success: () => {
        return this.render();
      }
    });
  }
}
