import {} from "jquery-ujs";

import { View } from "backbone.marionette";

export default class ChosenView extends View.extend({}) {
  initialize() {
    let msg = "Select an Option";
    if (this.$el.hasClass("month")) {
      msg = "Month";
    }
    if (this.$el.hasClass("year")) {
      msg = "Year";
    }
    if (this.$el.hasClass("day")) {
      msg = "Day";
    }
    const chosenOptions = {
      disable_search_threshold: 1000,
      allow_single_deselect: true,
      placeholder_text_single: msg,
      width: "100%"
    };
    return this.$el.chosen(chosenOptions);
  }
}
