var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			  <span style=\"display: none;\" data-behavior=\"range-placard\" class=\"placard\">\n				<span class=\"placard--header\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"placard_title") : depth0), depth0))
    + "</span>\n			  <span class=\"placard--content\">\n				  "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"placard_desc") : depth0), depth0))
    + "\n			  </span>\n		  </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"range--handle\"><b></b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"score") : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"possible") : depth0), depth0))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"range--handle\"><b></b>"
    + container.escapeExpression(__default(require("../../helpers/to_i.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"percentage") : depth0),{"name":"to_i","hash":{},"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":56}}}))
    + "%</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-behavior=\"range\" class=\"range "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"range_class") : depth0), depth0))
    + "\">\n	<h4 class=\"range--title\">\n		"
    + alias2(__default(require("../../helpers/titlecase.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"titlecase","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":23}}}))
    + "\n	</h4>\n\n	<div class=\"range--track\">\n	  <span style=\"width: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"percentage") : depth0), depth0))
    + "%\" class=\"range--fill\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"placard") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "	  </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"score") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":21,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n\n\n\n";
},"useData":true});