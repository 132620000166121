($ =>

  $.fn.ajaxChosen = function(settings, callback, chosenOptions) {
    if (settings == null) { settings = {}; }
    if (chosenOptions == null) { chosenOptions = {}; }
    const defaultOptions = {
      minTermLength: 3,
      afterTypeDelay: 500,
      jsonTermKey: "term",
      keepTypingMsg: "Keep typing...",
      lookingForMsg: "Looking for"
    };

    // This will come in handy later.
    const select = this;

    let chosenXhr = null;

    // Merge options with defaults
    const options = $.extend({}, defaultOptions, $(select).data(), settings);

    // Load chosen. To make things clear, I have taken the liberty
    // of using the .chosen-autoselect class to specify input elements
    // we want to use with ajax autocomplete.
    this.chosen(chosenOptions ? chosenOptions : {});
    console.log('test Z');

    return this.each(function() {
      // Now that chosen is loaded normally, we can bootstrap it with
      // our ajax autocomplete code.
      return $(this).next('.chosen-container')
      .find(".search-field > input, .chosen-search > input")
      .bind('keyup', function() {

          // This code will be executed every time the user types a letter
          // into the input form that chosen has created

          // Retrieve the current value of the input form
          const untrimmed_val = $(this).val();
          const val = $.trim($(this).val());

          // Depending on how much text the user has typed, let them know
          // if they need to keep typing or if we are looking for their data
          const msg = val.length < options.minTermLength ? options.keepTypingMsg : options.lookingForMsg + ` '${val}'`;
          select.next('.chosen-container').find('.no-results').text(msg);

          // If input text has not changed ... do nothing
          if (val === $(this).data('prevVal')) { return false; }

          // Set the current search term so we don't execute the ajax call if
          // the user hits a key that isn't an input letter/number/symbol
          $(this).data('prevVal', val);

          // At this point, we have a new term/query ... the old timer
          // is no longer valid.  Clear it.

          // We delay searches by a small amount so that we don't flood the
          // server with ajax requests.
          if (this.timer) { clearTimeout(this.timer); }

          // Some simple validation so we don't make excess ajax calls. I am
          // assuming you don't want to perform a search with less than 3
          // characters.
          if (val.length < options.minTermLength) { return false; }

          // This is a useful reference for later
          const field = $(this);

          // Default term key is `term`.  Specify alternative in options.options.jsonTermKey
          if (options.data == null) { options.data = {}; }
          options.data[options.jsonTermKey] = val;
          if (options.dataCallback != null) { options.data = options.dataCallback(options.data); }

          // If the user provided an ajax success callback, store it so we can
          // call it after our bootstrapping is finished.
          const { success } = options;

          // Create our own callback that will be executed when the ajax call is
          // finished.
          options.success = function(data) {
            // Exit if the data we're given is invalid
            if (data == null) { return; }

            // Go through all of the <option> elements in the <select> and remove
            // ones that have not been selected by the user.  For those selected
            // by the user, add them to a list to filter from the results later.
            const selected_values = [];
            select.find('option').each(function() {
              if (!$(this).is(":selected")) {
                return $(this).remove();
              } else {
                return selected_values.push($(this).val() + "-" + $(this).text());
              }
            });
            select.find('optgroup:empty').each(function() {
              return $(this).remove();
            });


            // Send the ajax results to the user callback so we can get an object of
            // value => text pairs to inject as <option> elements.
            const items = (callback != null) ? callback(data, field) : data;


            let nbItems = 0;

            // Iterate through the given data and inject the <option> elements into
            // the DOM if it doesn't exist in the selector already
            $.each(items, function(i, element) {
              nbItems++;

              if (element.group) {
                let group = select.find(`optgroup[label='${element.text}']`);
                if (!group.size()) { group = $("<optgroup />"); }

                group.attr('label', element.text)
                .appendTo(select);
                return $.each(element.items, function(i, element) {
                  let text, value;
                  if (typeof element === "string") {
                    value = i;
                    text = element;
                  } else {
                    ({ value } = element);
                    ({ text } = element);
                  }
                  if ($.inArray(value + "-" + text, selected_values) === -1) {
                    return $("<option />")
                    .attr('value', value)
                    .html(text)
                    .appendTo(group);
                  }
                });
              } else {
                let text, value;
                if (typeof element === "string") {
                  value = i;
                  text = element;
                } else {
                  ({ value } = element);
                  ({ text } = element);
                }
                if ($.inArray(value + "-" + text, selected_values) === -1) {
                  return $("<option />")
                  .attr('value', value)
                  .html(text)
                  .appendTo(select);
                }
              }
            });

            if (nbItems) {
              // Tell chosen that the contents of the <select> input have been updated
              // This makes chosen update its internal list of the input data.
              select.trigger("chosen:updated");
            } else {
              // If there are no results, display the no_results text
              select.data().chosen.no_results_clear();
              select.data().chosen.no_results(field.val());
            }

            // Finally, call the user supplied callback (if it exists)
            if (settings.success != null) { settings.success(data); }

            // For some reason, the contents of the input field get removed once you
            // call trigger above. Often, this can be very annoying (and can make some
            // searches impossible), so we add the value the user was typing back into
            // the input field.
            return field.val(untrimmed_val);
          };

          // Because non-ajax Chosen isn't constantly re-building results, when it
          // DOES rebuild results (during chosen:updated above, it clears the input
          // search field before scaling it.  This causes the input field width to be
          // at it's minimum, which is about 25px.

          // The proper way to fix this would be create a new method in chosen for
          // rebuilding results without clearing the input field.  Or to call
          // Chosen.search_field_scale() after resetting the value above.  This isn't
          // possible with the current state of Chosen.  The quick fix is to simply reset
          // the width of the field after we reset the value of the input text.
          // field.css('width','auto')

          // Execute the ajax call to search for autocomplete data with a timer
          return this.timer = setTimeout(function() {
            if (chosenXhr) { chosenXhr.abort(); }
            return chosenXhr = $.ajax(options);
          }
          , options.afterTypeDelay);
      });
    });
  }
)(jQuery);
