var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "switch-checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-model-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":25}}}) : helper)))
    + "\" class=\"evaluation-collection evaluation-collection-instructor\">\n	<div data-behavior=\"toggle-children\">\n		<div data-behavior=\"range\" class=\"range range-expandable\">\n			<h4 class=\"range--title\">\n				Your Evaluation\n			</h4>\n\n			<div class=\"range--track\">\n				<span data-behavior=\"range-fill\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"total_percentage_rounded") || (depth0 != null ? lookupProperty(depth0,"total_percentage_rounded") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_percentage_rounded","hash":{},"data":data,"loc":{"start":{"line":9,"column":51},"end":{"line":9,"column":79}}}) : helper)))
    + "%\"\n					  class=\"range--fill\"></span>\n\n				<div class=\"range--handle\"><b></b><span data-container=\"percentage\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"total_percentage_rounded") || (depth0 != null ? lookupProperty(depth0,"total_percentage_rounded") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_percentage_rounded","hash":{},"data":data,"loc":{"start":{"line":12,"column":72},"end":{"line":12,"column":100}}}) : helper)))
    + "</span>%\n				</div>\n			</div>\n		</div>\n	</div>\n	<ul class=\"evaluation-single--details evaluations-hidden\" data-behavior=\"child-container\">\n		<!-- criteria will be inserted here -->\n		<li data-anchor=\"child-insert-before\" data-behavior=\"subtotal\">\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Rubric Total</h4>\n\n					<div class=\"range--handle\">\n						<div class=\"score-wrapper\">\n							<span class=\"score\" data-container=\"subtotal-score\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"total_score") || (depth0 != null ? lookupProperty(depth0,"total_score") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_score","hash":{},"data":data,"loc":{"start":{"line":26,"column":59},"end":{"line":26,"column":74}}}) : helper)))
    + "</span>\n							<span class=\"slash\"></span>\n							<span class=\"possible\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"points_possible") || (depth0 != null ? lookupProperty(depth0,"points_possible") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"points_possible","hash":{},"data":data,"loc":{"start":{"line":28,"column":30},"end":{"line":28,"column":49}}}) : helper)))
    + "</span>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n		<li data-behavior=\"total\">\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Weighted Score</h4>\n\n					<div class=\"range--handle\">\n						<div class=\"score-wrapper\">\n							<span class=\"score\" data-container=\"total-score\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"total_score") || (depth0 != null ? lookupProperty(depth0,"total_score") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_score","hash":{},"data":data,"loc":{"start":{"line":41,"column":56},"end":{"line":41,"column":71}}}) : helper)))
    + "</span>\n							<span class=\"slash\"></span>\n							<span class=\"possible\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"points_possible") || (depth0 != null ? lookupProperty(depth0,"points_possible") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"points_possible","hash":{},"data":data,"loc":{"start":{"line":43,"column":30},"end":{"line":43,"column":49}}}) : helper)))
    + "</span>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n		<li>\n			<div class=\"range-tertiary range-editable range-total\">\n				<div class=\"range--track-wrapper\">\n					<h4 class=\"range-total--label\">Visible To Student?</h4>\n\n					<div class=\"range-total--value\">\n						<div class=\"switch "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":25},"end":{"line":55,"column":63}}})) != null ? stack1 : "")
    + "\">\n							<input data-behavior=\"publish-switch\" id=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":56,"column":58},"end":{"line":56,"column":65}}}) : helper)))
    + "\"\n								   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":11},"end":{"line":57,"column":52}}})) != null ? stack1 : "")
    + " class=\"switch-checkbox\" type=\"checkbox\">\n							<label for=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":58,"column":28},"end":{"line":58,"column":35}}}) : helper)))
    + "\" class=\"switch-label\">\n						    <span class=\"switch-inner\">\n						      <span class=\"switch-text switch-active\">Yes</span>\n						      <span class=\"switch-text switch-inactive\">No</span>\n						    </span>\n							</label>\n						</div>\n					</div>\n				</div>\n			</div>\n		</li>\n\n\n		<li data-behavior=\"utility\" class=\"evaluation-single--utility\">\n			<div>\n				<a data-behavior=\"evaluation-save\" class=\"primary-button update-button\" href=\"#\">Save Evaluation</a>\n				<a data-behavior=\"evaluation-destroy\" class=\"gentle-warning-button delete-button\" href=\"#\">Delete\n					Evaluation</a>\n			</div>\n\n			<div style=\"margin-top: 20px; display: none\" data-behavior=\"notice\" class=\"alert alert-notice\">\n			</div>\n\n		</li>\n	</ul>\n\n\n</li>\n";
},"useData":true});