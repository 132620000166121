/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import template from "templates/course_map/warning.hbs";

import { View, getOption } from "backbone.marionette";

export default class Warning extends View.extend({
  template
}) {
  serializeData() {
    const out = {
      isCreatorWarning: getOption(this, "warningType") === "Creator",
      isProjectWarning: getOption(this, "warningType") === "Project",
      isCreatorTypeGroup: getOption(this, "creatorType") === "Group",
      isCreatorTypeUser: getOption(this, "creatorType") === "User",
      creatorType: getOption(this, "creatorType"),
      warningType: getOption(this, "warningType"),
      courseId: getOption(this, "courseId"),
      canUpdateCourse:
        window.VocatUserCourseRole === "administrator" ||
        window.VocatUserCourseRole === "evaluator"
    };
    return out;
  }
}
