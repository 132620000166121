import { Behavior } from "backbone.marionette";
const jqueryUI = require("jquery-ui");

export default class SortableTable extends Behavior.extend({
  options: {},

  ui: {
    table: "tbody"
  }
}) {
  initialize() {
    this.listenTo(this, "updatesort", (args) => {
      return this.updateSort(args[0], args[1]);
    });
  }

  onRender() {
    this.getUI("table").sortable({
      handle: ".row-handle",
      items: 'tr:not([data-ui-behavior="drag-disabled"])',
      cursor: "move",
      revert: 175,

      helper: (index, $el) => {
        const $originals = $el.children();
        const $helper = $el.clone();
        $helper
          .children()
          .each((index, el) =>
            $(el).outerWidth($originals.eq(index).outerWidth())
          );
        return $helper;
      },

      stop(event, ui) {
        return ui.item.trigger("drop", ui.item.index());
      }
    });
  }

  updateSort(model, position) {
    const adjustedPosition = position;
    this.view.collection.remove(model);
    model.set("listing_order_position", adjustedPosition);
    this.view.collection.add(model, { at: position });
    return model.save();
  }
}
