import {} from 'jquery-ujs'
import Modernizr from 'modernizr';
import { Application } from "backbone.marionette";
import AppRouter from "marionette.approuter";
import Pikaday from 'pikaday';
import ModalLayoutView from 'views/modal/modal_layout';
import ModalConfirmView from 'views/modal/modal_confirm';
import DropdownView from 'views/layout/dropdown';
import CourseSubheaderView from 'views/layout/course_subheader';
import FileBrowserView from 'views/layout/file_browser';
import ChosenView from 'views/layout/chosen';
import JumpNavView from 'views/layout/jump_nav';
import FileInputView from 'views/layout/file_input';
import HeaderDrawerView from 'views/layout/header_drawer';
import HeaderDrawerTriggerView from 'views/layout/header_drawer_trigger';
import NotificationLayoutView from 'views/notification/notification_layout';
import NotificationExceptionView from 'views/notification/notification_exception';
import project from 'views/course/manage/projects/project';
import manage_organization from 'views/manage/organizations/organization';
import {
  CourseController,
  ProjectController,
  AdminController,
  CourseMapController,
  GroupController,
  PageController,
  PortfolioController,
  RubricController,
  SubmissionController,
  EmbedController
} from 'controllers';
import routes from "./routes";

const Standalone = {
  project,
  manage_organization
};

const appChannel = Radio.channel('app');

const VocatApp = Application.extend({
  region: '#region-main',

  onBeforeStart: function() {
    // Announce some key events on the global channel
    const globalChannel = Radio.channel('globalChannel');
    $('html').bind('click', event => globalChannel.trigger('user:action', event));
  },

  onStart: function() {
    // Attach views to existing dom elements
    $('[data-behavior="dropdown"]').each( (index, el) => new DropdownView({el, vent: appChannel}));
    $('[data-behavior="header-drawer-trigger"]').each( (index, el) => new HeaderDrawerTriggerView(({el, vent: appChannel})));
    $('[data-behavior="header-drawer"]').each( (index, el) => new HeaderDrawerView({el, vent: appChannel}));
    $('[data-behavior="chosen"]').each( (index, el) => new ChosenView({el, vent: appChannel}));
    $('[data-behavior="jump-nav"]').each( (index, el) => new JumpNavView({el, vent: appChannel}));
    $('[data-behavior="file-input"]').each( (index, el) => new FileInputView({el, vent: appChannel}));
    $('[data-behavior="course-subheader"]').each( (index, el) => new CourseSubheaderView({el, vent: appChannel}));
    $('[data-behavior="file-browser"]').each( (index, el) => new FileBrowserView({el, vent: appChannel}));
    $('[data-behavior="date-picker"]').each( (index, el) => new Pikaday(({field: el, format: 'MM/DD/YYYY'})));
    $('[data-region="global-notifications"]').each( (index, el) => new NotificationLayoutView({el, vent: appChannel}).render());
    $('[data-region="modal"]').each( (index, el) => new ModalLayoutView({el, vent: appChannel}));

    // Setup exception handler
    this.listenTo(appChannel, 'exception', reason => {
      this.getRegion().reset();
      return appChannel.trigger('notification:show', new NotificationExceptionView({msg: reason}));
    });

    // Handle confirmation on data-modalconfirm elements
    $('[data-modalconfirm]').each((index, el) => {
      const $el = $(el);
      return $el.on('click', e => {
        if (!$el.hasClass('modal-blocked')) {
          e.preventDefault();
          e.stopPropagation();
          return appChannel.trigger('modal:open', new ModalConfirmView({
            vent: this,
            descriptionLabel: $el.attr('data-modalconfirm'),
            confirmElement: $el,
            dismissEvent: 'dismiss:publish'
          }));
        }
      });
    });

    // To reduce the amount of loading in development context, we load router/controller pairs dynamically.
    // TODO: Improve this for better IE support.
    let fragment;
    const pushStateEnabled = Modernizr.history;
    Backbone.history.start({pushState: pushStateEnabled });
    if (pushStateEnabled) {
      fragment = Backbone.history.getFragment();
    } else {
      fragment = window.location.pathname.substring(1);
    }

    Backbone.history.stop();
    const regexes = {};
    let controllerName = false;
    const router = new Backbone.Router;
    _.each(routes, function(subRoutes, routeKey) {
      if (regexes[routeKey] == null) { regexes[routeKey] = []; }
      return _.each(subRoutes, function(controllerMethod, subRoute) {
        const regex = router._routeToRegExp(subRoute);
        regexes[routeKey].push(regex);
        if (fragment.match(regex)) {
          return controllerName = routeKey;
        }
      });
    });

    
    if (controllerName !== false) {
      const instantiateRouter = (Controller, controllerName) => {
        const subRoutes = routes[controllerName];
        this.router = new AppRouter({
          controller: new Controller,
          appRoutes: subRoutes
        });
        if (!Backbone.History.started) {
          Backbone.history.start({pushState: pushStateEnabled });
        }
        if (pushStateEnabled === false) {
          return router.navigate(fragment, { trigger: true });
        }
      };

      if (controllerName === 'course') instantiateRouter(CourseController, 'course')
      if (controllerName === 'project') instantiateRouter(ProjectController, 'project')
      if (controllerName === 'admin') instantiateRouter(AdminController, 'admin')
      if (controllerName === 'coursemap') instantiateRouter(CourseMapController, 'coursemap')
      if (controllerName === 'group') instantiateRouter(GroupController, 'group')
      if (controllerName === 'page') instantiateRouter(PageController, 'page')
      if (controllerName === 'portfolio') instantiateRouter(PortfolioController, 'portfolio')
      if (controllerName === 'rubric') instantiateRouter(RubricController, 'rubric')
      if (controllerName === 'submission') instantiateRouter(SubmissionController, 'submission')
      if (controllerName === 'embed') instantiateRouter(EmbedController, 'embed')
    }

    // Standalone views might require a router, so we instantiate them last.
    $('[data-standalone-view]').each( function(index, el) {
      const viewName = $(el).data().standaloneView;
      const standaloneView = new (Standalone[viewName])({el});
      return standaloneView;
    });

  }
});

const Vocat = new VocatApp;
window.Vocat = Vocat;

// Add CSRF token to Backbone requests
// https://www.ombulabs.com/blog/rails/backbone/security/adding-csrf-protection-to-your-rails-backbone-app.html
Backbone._sync = Backbone.sync;
Backbone.sync = function(method, model, options) {
  if (!options.noCSRF) {
    var beforeSend = options.beforeSend;

    // Set X-CSRF-Token HTTP header
    options.beforeSend = function(xhr) {
      var token = $('meta[name="csrf-token"]').attr('content');
      if (token) { xhr.setRequestHeader('X-CSRF-Token', token); }
      if (beforeSend) { return beforeSend.apply(this, arguments); }
    };
  }
  return Backbone._sync(method, model, options);
};
