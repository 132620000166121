/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import ProjectSubmissionRowView from 'views/project/detail/project_submission_row';
import { CollectionView, getOption } from "backbone.marionette";
import template from 'templates/project/detail/project_submission_list.hbs';

export default class ProjectSubmissionListView extends CollectionView.extend({
  tagName: "table",
  className: "table project-details-table",
  template: template,
  childView: ProjectSubmissionRowView,
  childViewContainer: "tbody"
}) {
  childViewOptions() { return {
    vent: this.vent
  }; }

  initialize(options) {
    this.options = options || {};
    this.vent = options.vent;
    this.projectId = getOption(this, 'projectId');
    return this.projectType = getOption(this, 'projectType');
  }
}
