/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/submission/submission_layout.hbs";
import { View, getOption } from "backbone.marionette";
import DiscussionView from "views/discussion/discussion";
import EvaluationsView from "views/submission/evaluations/evaluations_layout";
import AssetsView from "views/assets/assets_layout";
import UtilityView from "views/submission/utility/utility";
import ModalGroupMembershipView from "views/modal/modal_group_membership";
import ProjectModalView from "views/modal/modal_project_description";
import RubricModalView from "views/modal/modal_rubric";
import MarkdownOverviewModalView from "views/modal/modal_markdown_overview";
import RubricModel from "models/rubric";
import VisitCollection from "collections/visit_collection";
import DropdownView from "views/layout/dropdown";

export default class SubmissionLayout extends View.extend({
  template,
  children: {},
  courseMapContext: true,

  triggers: {
    "click @ui.openGroupModal": "open:groups:modal",
    "click @ui.close": "close",
    "click @ui.showProjectDescriptionModal": "open:project:modal",
    "click @ui.showRubric": "open:rubric:modal",
    "click @ui.showMarkdownOverview": "open:markdown:modal"
  },

  ui: {
    close: '[data-behavior="detail-close"]',
    openGroupModal: '[data-behavior="open-group-modal"]',
    showProjectDescriptionModal: '[data-behavior="open-project-description"]',
    showRubric: '[data-behavior="show-rubric"]',
    showMarkdownOverview: '[data-behavior="show-markdown-overview"]',
    goToCreator: '[data-behavior="go-to-creator"]'
  },

  regions: {
    flash: '[data-region="flash"]',
    evaluations: '[data-region="submission-evaluations"]',
    discussion: '[data-region="submission-discussion"]',
    assets: '[data-region="submission-assets"]',
    projectAssets: '[data-region="project-assets"]',
    utility: '[data-region="submission-utility"]'
  }
}) {
  serializeData() {
    const sd = {
      project: this.project.toJSON(),
      projectEvaluatable: this.model.get("project").evaluatable,
      courseId: this.courseId,
      creator: this.creator.toJSON(),
      creatorType: this.creatorType,
      isGroupProject: this.creatorType === "Group",
      courseMapContext: this.courseMapContext,
      pastDueDate: this.project.pastDue(),
      ...this.creatorNavData()
    };
    return sd;
  }

  creatorNavData() {
    const creatorCollection = this.creatorType === "Group" ? this.groups : this.users;
    if(!Array.isArray(creatorCollection) || creatorCollection.length <= 1) return { showCreatorNav: false };

    return {
      showCreatorNav: true,
      creators: this.users.toJSON(),
      groups: this.groups.toJSON(),
      prevCreator: this.prevCreator,
      nextCreator: this.nextCreator
    }
  }

  onOpenGroupsModal() {
    return Radio.channel("app").trigger(
      "modal:open",
      new ModalGroupMembershipView({ groupId: this.creator.id })
    );
  }

  onOpenProjectModal() {
    return Radio.channel("app").trigger(
      "modal:open",
      new ProjectModalView({ model: this.project })
    );
  }

  onOpenRubricModal() {
    const rubric = new RubricModel(this.project.get("rubric"));
    return Radio.channel("app").trigger(
      "modal:open",
      new RubricModalView({ model: rubric })
    );
  }

  onOpenMarkdownModal() {
    return Radio.channel("app").trigger(
      "modal:open",
      new MarkdownOverviewModalView()
    );
  }

  onClose() {
    let url;
    const context = this.model.get("creator_type").toLowerCase() + "s";
    if (this.courseMapContext) {
      url = `courses/${this.courseId}/${context}/evaluations`;
      return window.Vocat.router.navigate(url, true);
    }
    url = `/courses/${this.courseId}/portfolio`;
    return (window.location = url);
  }

  onDomRefresh() {
    window.scrollTo(0, 0);

    if (
      !this.$el
        .parent()
        .data()
        .hasOwnProperty("hideBackLink")
    ) {
      this.getUI("close").show();
    }
  }

  onRender() {
    this.showChildView(
      "discussion",
      new DiscussionView({ submission: this.model })
    );
    if (this.model.get("project").evaluatable) {
      this.showChildView(
        "evaluations",
        new EvaluationsView({
          rubric: this.rubric,
          vent: this,
          project: this.project,
          model: this.model,
          courseId: this.courseId
        })
      );
    }

    if (this.project.assets().length > 0) {
      this.showChildView(
        "projectAssets",
        new AssetsView({
          collection: this.project.assets(),
          model: this.project,
          submission: this.model,
          courseId: this.courseId,
          initialAsset: this.options.initialAsset,
          courseMapContext: this.courseMapContext,
          readOnly: true
        })
      );
    }
    this.showChildView(
      "assets",
      new AssetsView({
        collection: this.model.assets(),
        model: this.model,
        submission: this.model,
        courseId: this.courseId,
        initialAsset: this.options.initialAsset,
        courseMapContext: this.courseMapContext
      })
    );
    if (this.model.get("abilities").can_administer) {
      this.showChildView(
        "utility",
        new UtilityView({
          vent: this.vent,
          model: this.model,
          courseId: this.courseId
        })
      );
    }

    this.getUI("goToCreator").each(
      (index, el) =>
        new DropdownView({
          el,
          vent: window.Vocat.vent,
          allowAdjustment: false
        })
    );
  }

  initialize(options) {
    this.options = options || {};
    this.collections = {};
    this.courseId = getOption(this, "courseId");
    this.courseMapContext = getOption(this, "courseMapContext");
    this.project = this.model.project();
    this.creator = this.model.creator();
    this.creatorType = this.model.get("creator_type");
    this.users = getOption(this, "users");
    this.groups = getOption(this, "groups");
    this.nextCreator = getOption(this, "nextCreator");
    this.prevCreator = getOption(this, "prevCreator");

    const visitCollection = new VisitCollection();
    // eslint-disable-next-line new-cap
    this.visit = new visitCollection.model({
      visitable_type: "Submission",
      visitable_id: this.model.id,
      visitable_course_id: this.courseId
    });
    visitCollection.add(this.visit);
    return this.visit.save();
  }
}
