var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"formgrid oneline--input\">\n	<div class=\"button-wrapper\">\n		<a class=\"primary-button post-button\" data-behavior=\"post-save\" href=\"#\">Post Comment </a>\n	</div>\n	<div class=\"input-wrapper\">\n		<textarea data-parent=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":6,"column":25},"end":{"line":6,"column":35}}}) : helper)))
    + "\" data-behavior=\"post-input\"\n				  placeholder=\"Start writing a new comment...\"></textarea>\n	</div>\n	<div class=\"button-wrapper-responsive\">\n		<a class=\"primary-button post-button\" data-behavior=\"post-save\" href=\"#\">Post Comment</a>\n	</div>\n	<div>\n		<small>Use Markdown syntax to format your post. <a href=\"#\" data-behavior=\"show-markdown-overview\">Click here\n			for a brief overview.</a></small>\n	</div>\n</div>\n";
},"useData":true});