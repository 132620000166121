/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { isFunction } from "lodash";
import { View, getOption } from "backbone.marionette";
import template from "templates/property_editor/short_text_input.hbs";

export default class ShortTextInput extends View.extend({
  template,

  ui: {
    input: '[data-property="input"]',
    errorContainer: '[data-behavior="error-container"]'
  },

  saveModelOnSave: false,
  onSave: null,
  inputLabel: "Update Property",

  triggers: {
    'click [data-behavior="model-save"]': "click:model:save",
    'click [data-behavior="cancel"]': "click:model:cancel"
  }
}) {
  onClickModelCancel() {
    return Radio.channel("app").trigger("modal:close");
  }

  onClickModelSave() {
    this.save();
    if (isFunction(this.onSave)) {
      return this.onSave();
    }
  }

  getValue() {
    return this.getUI("input").val();
  }

  save() {
    const attr = {};
    attr[this.property] = this.getValue();

    // Save or set
    if (getOption(this, "saveModelOnSave") === true) {
      this.model.save(attr, { validate: true });
    } else {
      this.model.set(attr, { validate: true });
    }

    // Check if valid
    if (this.model.isValid()) {
      Radio.channel("app").trigger("modal:close");
      return this.trigger("model:updated");
    }
    const error = this.model.validationError;
    return this.updateError(error);
  }

  updateError(error) {
    this.getUI("errorContainer").html(error);
    return this.getUI("errorContainer").show();
  }

  initialize(options) {
    this.property = options.property;
    this.vent = options.vent;
    return (this.onSave = getOption(this, "onSave"));
  }

  serializeData() {
    return {
      value: this.model.get(this.property),
      label: getOption(this, "inputLabel"),
      saveClasses: getOption(this, "saveClasses"),
      saveLabel: getOption(this, "saveLabel")
    };
  }

  onRender() {
    const input = this.$el.find('[data-property="input"]');
    return setTimeout(() => input.focus(), 0);
  }
}
