var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-section\">\n	<div class=\"header-wrapper\">\n		<div class=\"header\">\n			<h2 class=\"heading-page-section\" data-behavior=\"label-container\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":77}}}) : helper)))
    + "</h2>\n		</div>\n	</div>\n	<div class=\"body\">\n		<form class=\"formgrid\">\n			<div data-region=\"flash\">\n			</div>\n			<div data-region=\"input\">\n			</div>\n			<div data-region=\"invite\">\n			</div>\n		</form>\n	</div>\n</div>\n\n<div class=\"page-section page-section-clear\">\n	<div data-region=\"list\" class=\"table-wrapper\">\n	</div>\n</div>\n\n";
},"useData":true});