var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"table-cell\">\n                            <p><strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</strong></p>\n                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"low") : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"high") : depth0), depth0))
    + "\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"table-row\">\n                    <div class=\"criteria-header table-cell\">\n                        <span><strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</strong></span>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"ranges") : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":30,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"table-cell\">\n                        <p style=\"text-align: center;\">\n                            <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "<br/>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"low") : depth0), depth0))
    + " to "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"high") : depth0), depth0))
    + "</strong>\n                        </p>\n\n                        <p>\n                            "
    + alias2(__default(require("../../helpers/find_rubric_cell_description.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? lookupProperty(depths[2],"cells") : depths[2]),depths[1],depth0,{"name":"find_rubric_cell_description","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":86}}}))
    + "\n                        </p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5 class=\"modal--header\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h5>\n<div class=\"modal-rubric\">\n    <div class=\"table-container\">\n        <div class=\"table-rubric\">\n            <div class=\"table-body\">\n                <div class=\"ranges table-row\">\n                    <div class=\"criteria-header table-cell spacer\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ranges") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":13,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":32,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n<div style=\"margin-top: 1em;\">\n	<a href=\"#\" class=\"primary-button close-button\" data-behavior=\"dismiss\">Close</a>\n</div>\n";
},"useData":true,"useDepths":true});