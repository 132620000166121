/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import EvaluationModel from "models/evaluation";

import { getOption } from "backbone.marionette";

export default class EvaluationCollection extends Backbone.Collection.extend({
  model: EvaluationModel
}) {
  initialize(models, options) {
    this.options = options;
    return (this.courseId = getOption(this, "courseId"));
  }

  url() {
    return `/api/v1/courses/${this.courseId}/evaluations`;
  }
}
