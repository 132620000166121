var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"evaluations-nav\">\n          <a class=\"header-link back-link\" data-behavior=\"detail-close\"\n              href=\"/courses/"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"courseId") : depth0), depth0))
    + "/"
    + alias1(__default(require("../../helpers/to_lower.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"creatorType") : depth0),{"name":"to_lower","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":66}}}))
    + "s/evaluations\">Back to Evaluations</a>\n\n            <div class=\"creator-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"prevCreator") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":15,"column":21}}})) != null ? stack1 : "")
    + "\n              <div class=\"dropdown dropdown-button\" data-behavior=\"go-to-creator\">\n                <a class=\"dropdown--toggle\" data-behavior=\"toggle\" href=\"#\">Go to...</a>\n                <div class=\"dropdown--options\">\n                  <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"isGroup") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + "                  </ul>\n                </div>\n              </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"nextCreator") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":34,"column":14},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"header-link prev-link\"\n                  href=\"/courses/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"courseId") : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"creatorType") : depth0),{"name":"to_lower","hash":{},"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":70}}}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"prevCreator") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Previous</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <a class=\"header-link prev-link disabled\"\n                  href=\"#\">Previous</a>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":24,"column":31}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li><a href=\"/courses/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"courseId") : depths[1]), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"creatorType") : depths[1]),{"name":"to_lower","hash":{},"data":data,"loc":{"start":{"line":23,"column":62},"end":{"line":23,"column":89}}}))
    + "s/evaluations/creator/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a></li>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"creators") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":22},"end":{"line":28,"column":31}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a class=\"header-link next-link\"\n                href=\"/courses/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"courseId") : depth0), depth0))
    + "/"
    + alias2(__default(require("../../helpers/to_lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"creatorType") : depth0),{"name":"to_lower","hash":{},"data":data,"loc":{"start":{"line":36,"column":44},"end":{"line":36,"column":68}}}))
    + "s/evaluations/creator/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"nextCreator") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Next</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                <a class=\"header-link next-link disabled\"\n                href=\"#\">Next</a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"#\" data-behavior=\"open-group-modal\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                </a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-section\">\n    <div class=\"page-content--heading header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCreatorNav") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "\n        <h2 class=\"heading-page-section projects-name-header\" style=\"display: block;\">\n            Course Portfolio\n        </h2>\n\n        <h3 class=\"header gray\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"creatorType") : depth0),"Group",{"name":"if_match","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":55,"column":25}}})) != null ? stack1 : "")
    + "        </h3>\n    </div>\n</div>\n\n<div class=\"page-section-spacing-top page-section\">\n	<div>\n		<div data-behavior=\"load-indicator\"><span class=\"primary-button loading\">Loading creator's portfolio...</span>\n		</div>\n		<div data-container=\"submission-summaries\">\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});