var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"annotation\">\n	<div class=\"annotation-wrapper\">\n		<div class=\"annotation-left\">\n			<img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"gravatar") || (depth0 != null ? lookupProperty(depth0,"gravatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gravatar","hash":{},"data":data,"loc":{"start":{"line":4,"column":13},"end":{"line":4,"column":25}}}) : helper)))
    + "\" />\n		</div>\n		<div class=\"annotation-right\">\n			<div class=\"annotation-top\">\n				<div class=\"annotation-creator role-"
    + alias4(((helper = (helper = lookupProperty(helpers,"author_role") || (depth0 != null ? lookupProperty(depth0,"author_role") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_role","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":55}}}) : helper)))
    + "\">\n					"
    + alias4(((helper = (helper = lookupProperty(helpers,"author_name") || (depth0 != null ? lookupProperty(depth0,"author_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":5},"end":{"line":9,"column":20}}}) : helper)))
    + "\n				</div>\n				<div class=\"annotation-timestamp\">\n					<span data-behavior=\"timecode\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"smpte_timecode") || (depth0 != null ? lookupProperty(depth0,"smpte_timecode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smpte_timecode","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":54}}}) : helper)))
    + "</span><br />\n				</div>\n			</div>\n			<div class=\"annotation-bottom markdown-content\" data-behavior=\"annotation-body\">\n				"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":14}}}) : helper))) != null ? stack1 : "")
    + "\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});