var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        It will be visible publicly"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"embed_annotations") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":11,"column":86}}})) != null ? stack1 : "")
    + ".\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " with annotations.\n        User names and profile images will not be displayed to logged-out users";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        It will only be visible"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"embed_annotations") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":80}}})) != null ? stack1 : "")
    + " to logged-in Vocat users. Logged out users will be presented with an authorization failure page.\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " with annotations";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5 class=\"modal--header\">Embedding Assets On Other Pages</h5>\n<div class=\"modal-embed\">\n  <div>\n    <div style=\"margin: 10px;\">\n      This asset can be embedded on other pages outside of Vocat.\n    </div>\n\n    <div style=\"margin: 10px\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"publicly_embeddable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div style=\"margin: 10px;\">\n      Copy the HTML snippet below and paste it into your website. Make sure that it goes into the\n      <span style=\"font-family: monospace;\">&lt;body&gt;</span> of the page where you'd like it to render (see notes).\n    </div>\n\n    <div style=\"display: flex; align-items: center;\">\n      <div style=\"border: 1px dashed white; margin: 10px; padding: 5px; font-family: monospace;\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"embed_snippet") || (depth0 != null ? lookupProperty(depth0,"embed_snippet") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"embed_snippet","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":25}}}) : helper)))
    + "\n      </div>\n      <div>\n        <button type=\"button\" class=\"primary-button\" data-copy>\n          Copy\n        </button>\n      </div>\n    </div>\n\n    <div style=\"margin: 10px;\">\n      Notes for advanced use:\n      <ul>\n        <li>\n          By default, the embed will render itself at the location where the snippet is placed.\n          If you'd like to have it render in a specific element, add the attribute\n          <span style=\"font-family: monospace;\">data-vocat-embed-target</span> and assign a value that matches the\n          <span style=\"font-family: monospace;\">id</span> attribute of the desired element.\n        </li>\n        <li>\n          If you don't specify an embed target, you can specify default styling (applied inline) of the embed's container.\n          To do so, add the attribute <span style=\"font-family: monospace;\">data-vocat-embed-style</span> and assign\n          the desired value to be added as the style attribute.\n        </li>\n      </ul>\n    </div>\n\n  </div>\n</div>\n<div style=\"margin-top: 1em;\">\n	<a href=\"#\" class=\"primary-button close-button\" data-behavior=\"dismiss\">Close</a>\n</div>\n";
},"useData":true});