/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import VocatController from "controllers/vocat_controller";
import AssetCollection from "collections/asset_collection";
import AssetModel from "models/asset";
import AssetDetail from "views/assets/asset_detail";

export default class EmbedController extends VocatController.extend({
  collections: {
    asset: new AssetCollection({})
  }
}) {
  assetDetail(assetId) {
    return this._loadAsset(assetId).done(asset => {
      const assetDetail = new AssetDetail({
        model: asset,
        context: "submission"
      });
      return window.Vocat.getRegion().show(assetDetail);
    });
  }

  _loadAsset(assetId) {
    const deferred = $.Deferred();
    const asset = new AssetModel({ id: assetId });
    asset.urlRoot = "/api/v1/embedded_assets";
    asset.fetch({
      success() {
        return deferred.resolve(asset);
      }
    });
    return deferred;
  }
}
