/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { extend } from "lodash";

import { View } from "backbone.marionette";

import ProjectTemplate from "templates/portfolio/portfolio_item_project.hbs";

export default class PortfolioItemSubmission extends View.extend({
  template: ProjectTemplate,
  className: "portfolio-frame",

  defaults: {
    showCourse: true,
    showCreator: true
  }
}) {
  initialize(options) {
    const extendedOptions = extend(this.defaults, options);
    this.showCourse = extendedOptions.showCourse;
    return (this.showCreator = extendedOptions.showCreator);
  }

  serializeData() {
    const data = super.serializeData();
    return {
      project: data,
      showCourse: this.showCourse,
      showCreator: this.showCreator
    };
  }
}
