var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"range-tag\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"high") : stack1), depth0))
    + ": "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <h2>Adjust Scores</h2>\n</div>\n<div class=\"modal-content\">\n    <div class=\"range-inputs\" data-region=\"ranges-inputs\">\n        <div class=\"range-input\">\n            <div class=\"range-pre\">\n                <span>Lowest Score</span>\n                <span class=\"alt\">Per Rubric Cell</span>\n            </div>\n            <input value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"low") : depth0), depth0))
    + "\" data-behavior=\"rubric-low\">\n            <div class=\"range-post\">\n                <span class=\"alt\">Min: 0</span>\n                <span class=\"alt\">Max: "
    + alias2(__default(require("../../helpers/calc_high.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ranges") : depth0),(depth0 != null ? lookupProperty(depth0,"high") : depth0),{"name":"calc_high","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":64}}}))
    + "</span>\n            </div>\n        </div>\n        <div class=\"range-input\">\n            <div class=\"range-pre\">\n                <span>Highest Score</span>\n                <span class=\"alt\">Per Rubric Cell</span>\n            </div>\n            <input value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"high") : depth0), depth0))
    + "\" data-behavior=\"rubric-high\">\n            <div class=\"range-post\">\n                <span class=\"alt\">Min: "
    + alias2(__default(require("../../helpers/calc_low.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ranges") : depth0),(depth0 != null ? lookupProperty(depth0,"low") : depth0),{"name":"calc_low","hash":{},"data":data,"loc":{"start":{"line":24,"column":39},"end":{"line":24,"column":62}}}))
    + "</span>\n                <span class=\"alt\">Max: 100</span>\n            </div>\n        </div>\n    </div>\n    <div class=\"range-picker-container\" data-region=\"range-picker\">\n        <span class=\"range-pre\">Drag to adjust individual range values:</span>\n        <div data-behavior=\"range-picker\" border=\"0\" class=\"range-picker\">\n            <div class=\"ticks\" data-container=\"ticks\"></div>\n            <div class=\"handles\" data-behavior=\"draggable-container\"></div>\n        </div>\n        <div class=\"range-post\">\n            <span>Scoring Key: </span>\n            <div class=\"range-tags\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"ranges") : depth0)) != null ? lookupProperty(stack1,"models") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":40,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});