import Pikaday from 'pikaday';
import AssetsView from "views/assets/assets_layout";
import ProjectModel from "models/project";
import { View } from "backbone.marionette";

export default class ProjectView extends View.extend({
  template: false,

  ui: {
    checkboxMediaAny: '[data-behavior="media-any"]',
    checkboxMediaSpecific: '[data-behavior="media-specific"]'
  },

  regions: {
    assets: '[data-region="assets"]',
  },

  triggers: {
    'change @ui.checkboxMediaAny': 'media:any:change',
    'change @ui.checkboxMediaSpecific': 'media:specific:change'
  }
}) {

  onMediaAnyChange() {
    if (this.getUI('checkboxMediaAny').prop('checked') === true) {
      return this.getUI('checkboxMediaSpecific').each((i, el) => $(el).prop('checked', false));
    }
  }

  onMediaSpecificChange() {
    const checkedCount = this.$el.find('[data-behavior="media-specific"]:checked').length;
    if (checkedCount > 0) {
      return this.getUI('checkboxMediaAny').prop('checked', false);
    } else {
      return this.getUI('checkboxMediaAny').prop('checked', true);
    }
  }

  isBlank(str) {
    let modifiedStr = str;
    if (modifiedStr === null) {
      modifiedStr = "";
    }
    return /^\s*$/.test(modifiedStr);
  }

  initialize() {

    let attr = {}
    const dataContainer = $(`#bootstrap-project`);
    if (dataContainer.length > 0) {
      const div = $("<div></div>");
      div.html(dataContainer.text());
      const text = div.text();
      if (!this.isBlank(text)) {
        const data = JSON.parse(text);
        attr = data.project;
      }
    }

    this.model = new ProjectModel(attr);
    this.showChildView(
      "assets",
      new AssetsView({
        collection: this.model.assets(),
        model: this.model,
        courseId: this.$el.data().courseId,
        isInlineInForm: true
      })
    );

  }
}
