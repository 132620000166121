var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p style=\"margin-bottom: 2rem;\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":43}}}) : helper)))
    + "</p>\n<a href=\"#\" class=\"gray-ex-button\" data-behavior=\"dismiss\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"dismissLabel") || (depth0 != null ? lookupProperty(depth0,"dismissLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dismissLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":75}}}) : helper)))
    + "</a>\n";
},"useData":true});