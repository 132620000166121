var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\"projects/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/edit\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"rubric_name") : depth0), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "		None\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "		User Project\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "		Group Project\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "		Open Project\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "		Yes\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "		No\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"row-handle\"><span></span></td>\n<th>\n	<a href=\"projects/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"abilities") : depth0)) != null ? lookupProperty(stack1,"can_update") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "	</a>\n</th>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"rubric_name") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":12,"column":1},"end":{"line":16,"column":8}}})) != null ? stack1 : "")
    + "</td>\n<td>\n"
    + ((stack1 = __default(require("../../../../helpers/if_match.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"UserProject",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":1},"end":{"line":21,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/if_match.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"GroupProject",{"name":"if_match","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":1},"end":{"line":24,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/if_match.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"OpenProject",{"name":"if_match","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":1},"end":{"line":27,"column":14}}})) != null ? stack1 : "")
    + "</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"evaluatable_by_peers") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":30,"column":1},"end":{"line":34,"column":8}}})) != null ? stack1 : "")
    + "</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"evaluatable_by_creator") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":37,"column":1},"end":{"line":41,"column":8}}})) != null ? stack1 : "")
    + "</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"allows_public_discussion") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":44,"column":1},"end":{"line":48,"column":8}}})) != null ? stack1 : "")
    + "</td>\n<td>\n	<div class=\"dropdown dropdown-button\" data-behavior=\"dropdown\">\n		<a class=\"dropdown--toggle\" data-behavior=\"toggle\" href=\"#\">Actions</a>\n\n		<div class=\"dropdown--options\" data-behavior=\"dropdown-options\">\n			<ul>\n				<li>\n					<a href=\"projects/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/edit\">Edit Project</a>\n				</li>\n				<li>\n					<a href=\"projects/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/export\">Export Scores</a>\n				</li>\n				<li>\n					<a href=\"projects/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-behavior=\"destroy\">Delete Project</a>\n				</li>\n			</ul>\n		</div>\n	</div>\n</td>\n";
},"useData":true});