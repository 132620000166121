/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { map } from "lodash";
import { View } from "backbone.marionette";
import template from 'templates/admin/enrollment/invite.hbs';

export default class Invite extends View.extend({
  template: template,

  ui: {
    button: '[data-behavior="submit-invite-and-enroll"]',
    input: '[data-behavior="contact"]'
  },

  triggers: {
    'click [data-behavior="cancel-invite-and-enroll"]': 'cancel',
    'click [data-behavior="submit-invite-and-enroll"]': 'submit'
  }
}) {
  onCancel() {
    return this.getUI('input').val('');
  }

  onSubmit() {
    let endpoint;
    const contact_string = this.getUI('input').val();
    this.getUI('button').addClass('loading');
    if (this.collection.searchType() === 'user') {
      endpoint = this.collection.bulkUrl();
    }
    return $.ajax(endpoint, {
      type: 'POST',
      dataType: 'json',
      data: {contacts: contact_string, invite: true},
      success: (data, textStatus, jqXHR) => {
        return this.handleSubmitSuccess(jqXHR.responseJSON);
      },
      error: (jqXHR, textStatus, error) => {
        if (jqXHR.hasOwnProperty('responseJSON') && jqXHR.responseJSON.hasOwnProperty('errors')) {
          error = jqXHR.responseJSON.errors;
        } else {
          error = 'The server failed to process the invitation.';
        }
        Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 5000, msg: error});
        this.getUI('button').removeClass('loading');
        return this.onCancel();
      }
    });
  }

  handleSubmitSuccess(response) {
    this.getUI('button').removeClass('loading');
    const successes = [];
    const failures = [];
    response.forEach(contact => {
      if (contact.success === true) {
        return successes.push(contact);
      } else {
        return failures.push(contact);
      }
    });
    if (successes.length > 0) {
      Radio.channel('app').trigger('error:add', {level: 'notice', lifetime: 10000, msg: map(successes, 'message')});
    };
    if (failures.length > 0) {
      Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 10000, msg: map(failures, 'message')});
    };
    this.collection.fetch();
    return this.onCancel();
  }

  initialize(options) {
    return this.vent = options.vent;
  }

  serializeData() {
    const out = {
      showInput: this.showInput,
      contacts: this.contacts
    };
    return out;
  }
}
