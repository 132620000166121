/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { shuffle } from "lodash";
import template from "templates/group/group_layout.hbs";
import AbstractMatrix from "views/abstract/abstract_matrix";
import CreatorsView from "views/group/creators";
import GroupsView from "views/group/groups";
import ModalConfirmView from "views/modal/modal_confirm";
import GroupMatrixView from "views/group/matrix";
import SaveNotifyView from "views/group/save_notify";
import WarningView from "views/group/warning";
import GroupWarningView from "views/group/group_warning";

export default class GroupLayout extends AbstractMatrix.extend({
  warningVisible: false,
  template,

  children: {},
  stickyHeader: false,
  regions: {
    creators: '[data-region="creators"]',
    groups: '[data-region="groups"]',
    matrix: '[data-region="matrix"]',
    warning: '[data-region="warning"]'
  },

  events: {},

  triggers: {
    'click [data-trigger="add"]': "click:group:add",
    'click [data-trigger="assign"]': "click:group:assign",
    'click [data-behavior="matrix-slider-left"]': "slider:left",
    'click [data-behavior="matrix-slider-right"]': "slider:right"
  },

  ui: {
    header: ".matrix--column-header",
    dirtyMessage: '[data-behavior="dirty-message"]',
    sliderContainer: '[data-behavior="matrix-slider"]',
    sliderLeft: '[data-behavior="matrix-slider-left"]',
    sliderRight: '[data-behavior="matrix-slider-right"]',
    hideOnWarning: '[data-behavior="hide-on-warning"]'
  }
}) {
  onDirty() {
    return Radio.channel("app").trigger(
      "notification:show",
      new SaveNotifyView({ collection: this.collections.group })
    );
  }

  onClickGroupAssign() {
    return Radio.channel("app").trigger(
      "modal:open",
      new ModalConfirmView({
        model: this.model,
        vent: this,
        descriptionLabel:
          "Each student will be randomly assigned to one group. If you proceed, students will lose their current group assignments.",
        confirmEvent: "confirm:assign",
        dismissEvent: "dismiss:assign"
      })
    );
  }

  onConfirmAssign() {
    const creatorIds = shuffle(this.collections.creator.map("id"));
    const creatorCount = creatorIds.length;
    const groupCount = this.collections.group.length;
    if (groupCount > 0) {
      const perGroup = Math.floor(creatorCount / groupCount);
      let remainder = creatorCount % groupCount;
      this.collections.group.each(function(group) {
        let take = perGroup;
        if (remainder > 0) {
          take++;
          remainder--;
        }
        return group.set("creator_ids", creatorIds.splice(0, take));
      });
      return this.onDirty();
    }
  }

  onClickGroupAdd() {
    // eslint-disable-next-line new-cap
    const model = new this.collections.group.model({
      name: this.collections.group.getNextGroupName(),
      course_id: this.courseId
    });
    model.save();
    return this.collections.group.add(model);
  }

  onDomRefresh() {
    if (this.collections.creator.length === 0) {
      this.showChildView(
        "warning",
        new WarningView({ courseId: this.courseId })
      );
      this.warningVisible = true;
      this.getUI("hideOnWarning").hide();
    }
    if (this.collections.group.length === 0) {
      this.showChildView(
        "warning",
        new GroupWarningView({ courseId: this.courseId, vent: this })
      );
      this.getUI("hideOnWarning").hide();
      return (this.warningVisible = true);
    }
    this.showChildView(
      "creators",
      new CreatorsView({
        collection: this.collections.creator,
        courseId: this.courseId,
        vent: this
      })
    );
    this.showChildView(
      "groups",
      new GroupsView({
        collection: this.collections.group,
        courseId: this.courseId,
        vent: this
      })
    );
    this.showChildView(
      "matrix",
      new GroupMatrixView({
        collection: this.collections.creator,
        collections: this.collections,
        courseId: this.courseId,
        vent: this
      })
    );
    setTimeout(() => {
      this.parentOnShow();
    }, 200);
    return (this.warningVisible = false);
  }

  onRender() {
    this.parentOnShow();
    this.listenTo(this.collections.group, "add", model => {
      const index = this.collections.group.indexOf(model);
      return this.slideToEnd();
    });
    return this.listenTo(this.collections.group, "add remove", () => {
      if (this.collections.group.length === 0) {
        setTimeout(() => this.render())
      }
      if (this.warningVisible === true) {
        setTimeout(() => this.render())
      }
    });
  }

  initialize(options) {
    this.collections = options.collections;
    this.courseId = options.courseId;
    return (this.collections.group.courseId = this.courseId);
  }
}
