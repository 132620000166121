/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import template from "templates/group/group_warning.hbs";

import { View, getOption } from "backbone.marionette";

export default class GroupWarning extends View.extend({
  template,

  triggers: {
    "click @ui.createGroup": "click:group:add"
  },

  ui: {
    createGroup: '[data-behavior="create-group"]'
  }
}) {
  onClickGroupAdd() {
    return this.vent.triggerMethod("click:group:add");
  }

  initialize(options) {
    return (this.vent = options.vent);
  }

  serializeData() {
    return {
      courseId: getOption(this, "courseId")
    };
  }
}
