/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { debounce, extend } from "lodash";
import { CollectionView } from "backbone.marionette";
import usersTemplate from 'templates/admin/enrollment/user_input.hbs';
import coursesTemplate from 'templates/admin/enrollment/course_input.hbs';
import ItemView from 'views/admin/enrollment/input_item';
import EmptyView from 'views/admin/enrollment/input_empty';
import ClosesOnUserAction from 'behaviors/closes_on_user_action';
require("jquery-ui");
require("ajax_chosen");

export default class EnrollmentUserInput extends CollectionView.extend({
  emptyView: EmptyView,
  childView: ItemView,
  childViewContainer: '[data-container="items"]',

  ui: {
    emptyViewContainer: '[data-container="empty"]',
    containerWrapper: '[data-behavior="container-wrapper"]',
    termInput: '[data-behavior="search-term"]',
    showBulk: '[data-behavior="show-bulk"]'
  },

  triggers: {
    'keypress [data-behavior="search-term"]': {
      event: "update",
      preventDefault: false,
      stopPropagation: false
    },
    'keyup [data-behavior="search-term"]': {
      event: "update",
      preventDefault: false,
      stopPropagation: false
    },

    'change [data-behavior="search-term"]': {
      event: "update",
      preventDefault: false,
      stopPropagation: false
    },
    'blur [data-behavior="search-term"]': {
      event: "blur",
      preventDefault: false,
      stopPropagation: false
    },
    'focus [data-behavior="search-term"]': 'focus',
    'click [data-behavior="show-bulk"]': 'showBulk'
  },

  onUpdate: debounce(function() {
    const promise = $.Deferred();
    promise.then(() => {
      if (this.getUI('termInput').is(":focus")) {
        return this.open();
      }
    });

    const term = this.getTerm();
    if (term.length >= 1) {
      const data = {};
      data[this.collection.getSearchTerm()] = term;
      this.trigger('input:changed', {value: term});
      this.collection.fetch({
        url: `${this.collection.url}/search`, data, success: () => {
          return promise.resolve();
        }
      });
    } else {
      this.collection.reset();
      promise.resolve();
    }


    return promise;
  }, 250),

  childViewEvents: {
    clicked() {
      this.getUI('termInput').val('');
      this.getUI('termInput').blur();
      const promise = this.onUpdate();
      return promise.then(() => {
        return this.close();
      });
    }
  }

}) {
  initialize(options) {
    this.collectionType = options.collectionType;
    this.enrollmentCollection = options.enrollmentCollection;
  }

  checkCollectionLength() {
    if (this.collection.length > 0) {
      return this.open();
    } else {
      return this.close();
    }
  }

  buildChildView(item, ItemViewType, childViewOptions) {
    const options = extend({model: item}, childViewOptions);
    options.enrollmentCollection = this.enrollmentCollection;
    options.vent = this.options.vent;
    options.collectionType = this.collectionType;
    const childView = new ItemViewType(options);
    return childView;
  }

  emptyViewOptions() {
    return {
      collectionType: this.collectionType
    }
  }

  getTemplate() {
    if (this.collection.getSearchTerm() === 'email') {
      return usersTemplate;
    } else {
      return coursesTemplate;
    }
  }

  close() {
    if (this.getUI('containerWrapper').is(':visible')) {
      this.getUI('containerWrapper').hide();
      return this.triggerMethod('closed');
    }
  }

  open() {
    if (!this.getUI('containerWrapper').is(':visible')) {
      this.getUI('containerWrapper').show();
      return this.triggerMethod('opened');
    }
  }

  getTerm() {
    return this.getUI('termInput').val().trim();
  }

  onFocus() {
    const term = this.getTerm();
    if (term.length >= 1) {
      return this.open();
    }
  }

  onBlur() {
    this.close();
    return true;
  }

  onDomRefresh() {
    return this.getUI('containerWrapper').hide();
  }
}
