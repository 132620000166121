/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { clone } from "lodash";

export default class EvaluationSetModel extends Backbone.Model.extend({}) {
  averageScore() {
    const numbers = this.get("evaluations").map("total_percentage");
    return Math.round(
      numbers.reduce((memo, num) => memo + num, 0) / numbers.length
    );
  }

  toJSON() {
    const attributes = clone(this.attributes);
    attributes.averageScore = this.averageScore();
    return attributes;
  }
}
