var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<h6 class=\"modal--subheader\">Due Date:</h6>\n\n		<p>\n			"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"due_date") : depth0), depth0))
    + "\n		</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5 class=\"modal--header\">"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h5>\n<div class=\"modal-scroll-container\">\n	<h6 class=\"modal--subheader\">Description:</h6>\n\n	<div class=\"markdown-content\">\n		"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0)) != null ? stack1 : "")
    + "\n	</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"due_date") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":1},"end":{"line":15,"column":8}}})) != null ? stack1 : "")
    + "\n	<h6 class=\"modal--subheader\">Allowed File Types:</h6>\n\n	<p>\n		"
    + ((stack1 = __default(require("../../helpers/join.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"allowed_extensions") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":20,"column":31}}})) != null ? stack1 : "")
    + "\n	</p>\n</div>\n\n<div style=\"margin-top: 1em;\">\n	<a href=\"#\" class=\"primary-button close-button\" data-behavior=\"dismiss\">Close</a>\n</div>\n";
},"useData":true});