var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"assets") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"media-submission\" data-region=\"media-submission\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                <div class=\"media-attributes\">\n                    <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + "\"/>\n                    <div>\n                        <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</p>\n                        <span>Uploaded "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"short_created_at") : depth0), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"media-counts\">\n                    <i class=\"annotation-count\"></i>\n                    <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"annotations_count") : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        No media uploaded\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"current_user_percentage") || (depth0 != null ? lookupProperty(depth0,"current_user_percentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"current_user_percentage","hash":{},"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":33,"column":39}}}) : helper)))
    + "%\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            &mdash;\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"peer_score_percentage") || (depth0 != null ? lookupProperty(depth0,"peer_score_percentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"peer_score_percentage","hash":{},"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":37}}}) : helper)))
    + "%\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"self_evaluation_percentage") || (depth0 != null ? lookupProperty(depth0,"self_evaluation_percentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"self_evaluation_percentage","hash":{},"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":51,"column":42}}}) : helper)))
    + "%\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"student-name\">\n    <span>\n        "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"creator") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n    </span>\n</td>\n<td class=\"media-submission-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"assets") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"number-cell narrow-cell\">\n    <span>"
    + alias1(((helper = (helper = lookupProperty(helpers,"discussion_posts_count") || (depth0 != null ? lookupProperty(depth0,"discussion_posts_count") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"discussion_posts_count","hash":{},"data":data,"loc":{"start":{"line":28,"column":10},"end":{"line":28,"column":36}}}) : helper)))
    + "</span>\n</td>\n<td class=\"number-cell\">\n    <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"current_user_percentage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</td>\n<td class=\"number-cell gray\">\n    <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"peer_score_percentage") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</td>\n<td class=\"number-cell gray\">\n    <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"self_evaluation_percentage") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</td>\n";
},"useData":true});