import { Behavior } from "backbone.marionette";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default class ClosesOnUserAction extends Behavior.extend({
  options: {
    closeMethod: "close"
  },

  triggers: {},

  ui: {}
}) {
  initialize() {
    return (this.globalChannel = Radio.channel("globalChannel"));
  }

  onOpened() {
    this.globalChannel.trigger("user:action");
    return this.listenTo(this.globalChannel, "user:action", event => {
      if (!event || !$.contains(this.el, event.target)) {
        return this.view[this.options.closeMethod]();
      }
    });
  }

  onClosed() {
    return this.stopListening(this.globalChannel, "user:action");
  }
}
