/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { template } from "lodash";
import { CollectionView } from "backbone.marionette";
import ItemView from 'views/assets/player/player_annotations_item';

export default class PlayerAnnotations extends CollectionView.extend({
  template: template(''),
  showTimePadding: 1,
  hideTimePadding: .1,
  tagName: 'ul',

  attributes: {
    class: 'annotations-overlay'
  },

  childView: ItemView
}) {
  childViewOptions(model, index) {
    return {
    vent: this.vent,
    assetHasDuration: this.model.hasDuration()
    };
  }

  setupListeners() {}

  initialize(options) {
    this.collection = this.model.annotations();
    this.vent = options.vent;
    return this.setupListeners();
  }
}
