import { View } from "backbone.marionette";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default class HeaderDrawerTriggerView extends View.extend({}) {
  onClickTrigger() {
    return this.globalChannel.trigger(`drawer:${this.drawerTarget}:toggle`);
  }

  initialize(options) {
    this.globalChannel = Radio.channel("globalChannel");
    this.vent = options.vent;
    this.drawerTarget = this.$el.data().drawerTarget;
    return this.$el.on("click", e => {
      e.stopPropagation();
      return this.onClickTrigger();
    });
  }
}
