/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { map } from "lodash";
import { View } from "backbone.marionette";
import template from 'templates/admin/enrollment/confirm_invite.hbs';
import GlobalNotification from 'behaviors/global_notification';

export default class ConfirmInvite extends View.extend({
  template: template,
  klass: 'confirmInvite',

  ui: {
    button: '[data-behavior="submit-invite-and-enroll"]'
  },

  triggers: {
    'click [data-behavior="cancel-invite-and-enroll"]': 'cancel',
    'click [data-behavior="submit-invite-and-enroll"]': 'submit'
  },

  behaviors: {
    globalNotification: {
      behaviorClass: GlobalNotification
    }
  }
}) {
  onCancel() {
    return Radio.channel('app').trigger('notification:empty');
  }

  onSubmit() {
    let endpoint;
    const contact_strings = new Array;
    this.contacts.forEach(contact => contact_strings.push(contact.string));
    const contact_string = contact_strings.join("\n");
    this.getUI('button').addClass('loading');
    if (this.collection.searchType() === 'user') {
      endpoint = this.collection.bulkUrl();
    }
    return $.ajax(endpoint, {
      type: 'POST',
      dataType: 'json',
      data: {contacts: contact_string, invite: true},
      success: (data, textStatus, jqXHR) => {
        return this.handleSubmitSuccess(jqXHR.responseJSON);
      },
      error: (jqXHR, textStatus, error) => {
        Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 5000, msg: jqXHR.responseJSON.errors});
        this.getUI('button').removeClass('loading');
        return this.onCancel();
      }
    });
  }

  handleSubmitSuccess(response) {
    this.getUI('button').removeClass('loading');
    const successes = [];
    const failures = [];
    response.forEach(contact => {
      if (contact.success === true) {
        return successes.push(contact);
      } else {
        return failures.push(contact);
      }
    });

    this.collection.fetch();
    this.onCancel();

    Radio.channel('app').trigger('error:add', {level: 'notice', lifetime: 10000, msg: map(successes, 'message')});
    return Radio.channel('app').trigger('error:add', {level: 'error', lifetime: 10000, msg: map(failures, 'message')});
  }

  initialize(options) {
    this.contacts = options.contacts;
    return this.vent = options.vent;
  }

  serializeData() {
    const out = {
      contact_emails: map(this.contacts, 'email').join(', '),
      contacts_count: this.contacts.length,
      multiple_contacts: this.contacts.length > 1,
      contacts: this.contacts
    };
    return out;
  }
}
