import template from "templates/discussion/discussion.hbs";
import AbstractDiscussionView from "views/abstract/abstract_discussion";
import DiscussionPostCollection from "collections/discussion_post_collection";
import PostCollectionView from "views/discussion/post_collection";

export default class DiscussionView extends AbstractDiscussionView.extend({
  template,
  ui: {
    bodyInput: '[data-behavior="post-input"]',
    countContainer: '[data-behavior="post-count"]'
  },
  regions: {
    postCollection: '[data-behavior="post-container"]'
  }
}) {
  initialize(options) {
    this.submission = options.submission;
    this.allPosts = new DiscussionPostCollection([], {});
    this.collection = new DiscussionPostCollection([], {});
    this.allPosts.fetch({
      data: { submission: this.submission.id },
      success: () => {
        this.collection.reset(this.allPosts.where({ parent_id: null }));
      }
    });
  }

  onRender() {
    this.renderPostCollection();
    this.updateCount();

    this.listenTo(this.allPosts, "add sync remove", () => {
      this.updateCount();
      this.renderPostCollection();
    });
  }

  renderPostCollection() {
    this.showChildView(
      "postCollection",
      new PostCollectionView({
        collection: this.collection,
        submission: this.submission,
        allPosts: this.allPosts
      })
    );
  }

  updateCount() {
    let s;
    const l = this.allPosts.length;
    if (l === 1) {
      s = "One Comment";
    } else {
      s = `${l} Comments`;
    }
    const countEl = this.getUI("countContainer");
    this.getUI("countContainer").html(s);
  }

  onReplyClear() {
    this.getUI("bodyInput").val("");
  }
}
