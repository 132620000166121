/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import LoadingView from "views/layout/loading";
import { MnObject } from "backbone.marionette";
import ModalErrorView from "views/modal/modal_error";
import forEach from "lodash/forEach";
import {} from "jquery-ujs";

export default class VocatController extends MnObject.extend({
  collections: {}
}) {
  initialize() {
    return this.bootstrapCollections();
  }

  isBlank(str) {
    let modifiedStr = str;
    if (modifiedStr === null) {
      modifiedStr = "";
    }
    return /^\s*$/.test(modifiedStr);
  }

  deferredCollectionFetching(collection, data, msg) {
    let modifiedMsg = msg;
    if (modifiedMsg == null) {
      modifiedMsg = "loading...";
    }
    const deferred = $.Deferred();
    window.Vocat.getRegion().show(new LoadingView({ modifiedMsg }));
    collection.fetch({
      reset: true,
      data,
      error: () => {
        return Radio.channel("app").trigger(
          "modal:open",
          new ModalErrorView({
            model: this.model,
            vent: this,
            message:
              "Exception: Unable to fetch collection models. Please report this error to your VOCAT administrator."
          })
        );
      },
      success: () => {
        return deferred.resolve();
      }
    });
    return deferred;
  }

  bootstrapCollections() {
    return forEach(this.collections, (collection, collectionKey) => {
      const dataContainer = $(`#bootstrap-${collectionKey}`);
      if (dataContainer.length > 0) {
        const div = $("<div></div>");
        div.html(dataContainer.text());
        const text = div.text();
        if (!this.isBlank(text)) {
          const data = JSON.parse(text);
          if (data[collectionKey] != null) {
            return collection.reset(data[collectionKey]);
          }
        }
      }
    });
  }
}
