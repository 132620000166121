import { CollectionView } from "backbone.marionette";
import PostView from "views/discussion/post";

export default class PostCollectionView extends CollectionView.extend({
  childView: PostView,
  childViewTriggers: {
    "post:save": "child:post:save",
    "post:delete": "child:post:delete"
  }
}) {
  initialize(options) {
    this.submission = options.submission;
    this.allPosts = options.allPosts;
  }

  childViewOptions() {
    return {
      allPosts: this.allPosts,
      submission: this.submission
    };
  }

  onReplyClear() {
    return this.getUI("bodyInput").val("");
  }

  resetInput() {
    this.triggerMethod("reply:clear");
    return this.triggerMethod("reply:toggle");
  }
}
