import videojs from "video.js";
import WaveSurfer from "wavesurfer.js";
import { isNaN } from "lodash";

const Plugin = videojs.getPlugin('plugin');

class VjsAudioWave extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.callStack = 0;
    this.options = options;
    this.ignoreSurferSeek = false;

    const containerProperties = {
      className: 'vjs-waveform',
      tabIndex: 0
    };
    const container = videojs.dom.createEl("div", containerProperties);
    this.container = $(container);

    const surferOptions = {
      height: this.player.height() - 48,
      container,
      progressColor: '#43B5AE',
      waveColor: '#6d6e71',
      interact: false,
      msDisplayMax: 10,
      hideScrollbar: true
    };
    this.surfer = WaveSurfer.create(surferOptions);
    this.surfer.load(options.src);

    $(this.player.el()).find('.vjs-tech').after(container);

    this.setupListeners();
  }

  setupListeners() {
    this.player.on('timeupdate', event => this.updateSurferPosition(event));

    // prevent controlbar fadeout
    this.player.on('userinactive', event => this.player.userActive(true));

    this.container.on('click', event => {
      this.requestSeek(event);
    });
  }

  requestSeek(event) {
    const { offsetX } = event;
    const width = this.container.width();
    const percentage = offsetX / width;
    const duration = this.player.duration();
    const seconds = duration * percentage;
    this.player.currentTime(seconds);
  }

  updateSurferPosition(event) {
    const time = this.player.currentTime();
    const duration = this.player.duration();
    const percentage = time / duration;

    if (!isNaN(percentage)) {
      this.surfer.seekTo(percentage);
    }
  }
}

videojs.registerPlugin('audiowave', VjsAudioWave);

export default VjsAudioWave;
