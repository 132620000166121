import { View, getOption } from "backbone.marionette";
import template from "templates/course_map/filters.hbs";

export default class CourseMapFiltersView extends View.extend({
  tagName: "div",
  template,
  ui: {
    form: '[data-behavior="filter-form"]',
    nameField: "#filterByProject"
  },
  triggers: {
    "submit @ui.form": "submit",
    "reset @ui.form": "reset"
  }
}) {
  initialize(options) {
    this.vent = options.vent;
    this.options = options || {};
  }

  // Submit filter values to parent view
  onSubmit() {
    const name = this.getUI("nameField").val();

    return this.vent.triggerMethod("filter:projects", {
      name
    });
  }

  // Reset fields
  onReset() {
    this.getUI("nameField").val("");

    return this.vent.triggerMethod("filter:projects", {});
  }
}
