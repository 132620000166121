import videojs from "video.js";
import template from "./annotations.hbs";

const Plugin = videojs.getPlugin('plugin');

class VjsAnnotationsPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.activationHandler = this.activationHandler.bind(this);
    this.player = player;
    this.$playerEl = $(player.el());
    this.options = options;
    this.vent = options.vent;
    this.collection = options.collection;
    this.container = $('<div class="vjs-annotation"></div');
    this.$playerEl.find('.vjs-control-bar').before(this.container);
    this.setupListeners();
  }

  activationHandler() {
    const annotation = this.collection.findWhere({active: true});
    this.showAnnotation(annotation);
  }

  setupListeners() {
    this.collection.on('model:activated', this.activationHandler);

    this.player.on('fullscreenchange', event => {
      if (this.player.isFullscreen()) {
        this.container.show();
      } else {
        this.container.hide();
      }
    });

    this.player.on('dispose', () => {
      this.collection.off('model:activated', this.activationHandler);
    });
  }

  showAnnotation(annotation) {
    this.container.html(template(annotation.toJSON()));
  }
}

videojs.registerPlugin('annotations', VjsAnnotationsPlugin);

export default VjsAnnotationsPlugin;
