var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"thumb-container thumb-container-video\">\n				<a href=\"#\">\n					<img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + "\">\n				</a>\n			</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"thumb-container-placeholder\">\n				<a href=\"#\">\n					<span class=\"tight\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),"processing_error",{"name":"if_match","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "					</span>\n\n					<div class=\"processing-indicator\">\n						<div class=\"processing-indicator-1\"></div>\n						<div class=\"processing-indicator-2\"></div>\n					</div>\n				</a>\n			</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "							ERROR\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(__default(require("../../helpers/to_upper.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),{"name":"to_upper","hash":{},"data":data,"loc":{"start":{"line":16,"column":7},"end":{"line":16,"column":36}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"thumb-container thumb-container-audio\">\n			<a href=\"#\">\n				<img src=\"/static_assets/images/audio_placeholder.png\"/>\n			</a>\n		</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":36,"column":2},"end":{"line":59,"column":9}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"thumb-container thumb-container-image\">\n				<a href=\"#\">\n					<img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + "\">\n				</a>\n			</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"thumb-container-placeholder\">\n				<a href=\"#\">\n					<span class=\"tight\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),"processing_error",{"name":"if_match","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":46,"column":6},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + "					</span>\n\n					<div class=\"processing-indicator\">\n						<div class=\"processing-indicator-1\"></div>\n						<div class=\"processing-indicator-2\"></div>\n					</div>\n				</a>\n			</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							<b>ERROR</b>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(__default(require("../../helpers/titlecase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"family") : depth0),{"name":"titlecase","hash":{},"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":68,"column":36}}}))
    + " Media\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "					- <b>ERROR</b>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					- <b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "</b>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <ul style=\"display: inline-block;\" class=\"bubble\" data-behavior=\"hide-on-manage\">\n        <li data-behavior=\"post-count\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"annotationCount") : depth0),1,{"name":"if_match","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":88,"column":10},"end":{"line":92,"column":23}}})) != null ? stack1 : "")
    + "        </li>\n      </ul>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"annotationCount") : depth0), depth0))
    + " Annotation\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"annotationCount") : depth0), depth0))
    + " Annotations\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-behavior=\"show\" class=\"portfolio-item--thumb\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"family") : depth0),"video",{"name":"if_match","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":27,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"family") : depth0),"audio",{"name":"if_match","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":1},"end":{"line":34,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"family") : depth0),"image",{"name":"if_match","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":1},"end":{"line":60,"column":14}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"asset-collection-item--content\">\n    <h3 class=\"heading-tertiary\" style=\"font-family: 'proxima-nova-condensed'; font-weight: 400; margin-top: 0; text-transform: none; font-size: 26px; letter-spacing: normal;\">\n        <a href=\"#\" data-behavior=\"show\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":69,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </h3>\n    <h5 class=\"heading-informational\">\n        "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"file_info") : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attachment") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),"processing_error",{"name":"if_match","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":78,"column":17}}})) != null ? stack1 : "")
    + "    </h5>\n    <h5 class=\"heading-informational\">\n        Uploaded "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"created_at") : depth0), depth0))
    + "\n    </h5>\n	</div>\n	<div class=\"asset-collection-item--utility\">\n"
    + ((stack1 = __default(require("../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"assetable_type") : depth0),"Submission",{"name":"if_match","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":4},"end":{"line":95,"column":17}}})) != null ? stack1 : "")
    + "		<div data-behavior=\"show-on-manage\">\n			<a href=\"#\" style=\"display: inline-block\" class=\"tertiary-button edit-button\"\n			   data-behavior=\"rename\">Rename</a>\n			<a href=\"#\" style=\"display: inline-block\" class=\"gentle-warning-button delete-button\"\n			   data-behavior=\"destroy\">Delete</a>\n		</div>\n		<div class=\"move-links\" data-behavior=\"show-on-manage\">\n			<Span class=\"move-label\">List Order:</span>\n			<a href=\"#\" style=\"display: inline-block;\" class=\"down-link disabled\" data-behavior=\"move-down\">Move\n				Down</a>\n			<a href=\"#\" style=\"display: inline-block;\" class=\"up-link disabled\" data-behavior=\"move-up\">Move Up</a>\n		</div>\n</div>\n";
},"useData":true});