var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<th><a href=\"/admin/courses/"
    + alias4(((helper = (helper = lookupProperty(helpers,"course") || (depth0 != null ? lookupProperty(depth0,"course") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"course","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":38}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"course_name") || (depth0 != null ? lookupProperty(depth0,"course_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"course_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":55}}}) : helper)))
    + "</a></th>\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"role") || (depth0 != null ? lookupProperty(depth0,"role") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"role","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":12}}}) : helper)))
    + "</td>\n<td><a data-behavior=\"destroy\" href=\"\" class=\"gentle-warning-button delete-button\">Drop Course</a></td>";
},"useData":true});