/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import ProjectCollection from 'collections/project_collection';
import { CollectionView } from "backbone.marionette";
import template from 'templates/course/manage/projects/projects.hbs';
import ProjectsRowView from 'views/course/manage/projects/project_row';
import SortableTable from 'behaviors/sortable_table';

export default class Projects extends CollectionView.extend({
  template: template,
  childView: ProjectsRowView,
  childViewContainer: 'tbody',

  behaviors: {
    sortableTable: {
      behaviorClass: SortableTable
    }
  },

  childViewEvents: {
    updatesort(args) {
      this.trigger("updatesort", args)
    }
  }

}) {
  initialize(options) {}
}
