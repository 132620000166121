/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import template from "templates/rubric/criteria.hbs";

import { CollectionView } from "backbone.marionette";

import ItemView from "views/rubric/criteria_item";

export default class Criteria extends CollectionView.extend({
  template,
  className: "criteria",
  childViewContainer: '[data-region="criteria-rows"]',
  childView: ItemView,

  ui: {
    criteriaAdd: ".criteria-add-button",
    criteriaInstruction: ".criteria-instruction"
  }
}) {
  childViewOptions() {
    return {
      collection: this.collection
    };
  }

  showCriteriaAdd() {
    if (this.collection.length > 3) {
      return $(this.getUI("criteriaAdd")).css("display", "none");
    }
    return $(this.getUI("criteriaAdd")).css("display", "inline-block");
  }

  showCriteriaInstruction() {
    if (this.collection.length > 0) {
      return $(this.getUI("criteriaInstruction")).css("display", "none");
    }
    return $(this.getUI("criteriaInstruction")).css("display", "inline-block");
  }

  onDomRefresh() {
    this.showCriteriaAdd();
    this.showCriteriaInstruction();
  }

}
