/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/layout/loading.hbs";

import { View, getOption } from "backbone.marionette";

export default class LoadingView extends View.extend({
  msg: "Loading...",
  template
}) {
  serializeData() {
    return {
      msg: getOption(this, "msg")
    };
  }

  initialize(options) {
    return (this.options = options);
  }
}
