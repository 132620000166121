/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import EnrollmentModel from "models/enrollment";
import UserCollection from "collections/user_collection";
import CourseCollection from "collections/course_collection";

export default class EnrollmentCollection extends Backbone.Collection.extend({
  model: EnrollmentModel
}) {
  searchType() {
    if (this.scope.course) {
      return "user";
    }
    return "course";
  }

  role() {
    return this.scope.role;
  }

  getSearchCollection() {
    if (this.searchType() === "user") {
      return new UserCollection();
    }
    return new CourseCollection();
  }

  getContextualName(model) {
    if (this.searchType() === "user") {
      return model.get("user_name");
    }
    return model.get("course_name");
  }

  newEnrollmentFromSearchModel(searchModel) {
    const attributes = {
      course: searchModel.id,
      user: searchModel.id
    };
    if (this.scope.course != null) {
      attributes.course = this.scope.course;
    }
    if (this.scope.user != null) {
      attributes.user = this.scope.user;
    }
    if (this.scope.role != null) {
      attributes.role = this.scope.role;
    }

    // eslint-disable-next-line new-cap
    const model = new this.model(attributes);
    return model;
  }

  baseUrl() {
    if (this.searchType() === "user") {
      return `/api/v1/courses/${this.scope.course}/enrollments`;
    }
    return `/api/v1/users/${this.scope.user}/enrollments`;
  }

  bulkUrl() {
    if (this.searchType() === "user") {
      return `${this.baseUrl()}/bulk?role=${this.scope.role}`;
    }
    return `${this.baseUrl()}/bulk`;
  }

  url() {
    if (this.searchType() === "user") {
      return `${this.baseUrl()}?role=${this.scope.role}`;
    }
    return this.baseUrl();
  }

  comparator(model) {
    if (this.searchType() === "user") {
      return model.get("user_name");
    }
    return model.get("course_name");
  }

  initialize(models, options) {
    return (this.scope = options.scope);
  }
}
