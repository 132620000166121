/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

module.exports = function(matchA, matchB, options) {
  if (matchA === matchB) {
    return options.fn(this);
  }
  return options.inverse(this);
};
