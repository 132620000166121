/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { isObject } from "lodash";
import AssetCollection from "collections/asset_collection";

export default class ProjectModel extends Backbone.Model.extend({
  urlRoot: "/api/v1/projects"
}) {
  hasRubric() {
    return isObject(this.get("rubric"));
  }

  type() {
    return "ProjectModel"
  }

  assets() {
    return this.assetCollection;
  }

  updateAssetsCollection() {
    if (!this.assetCollection) {
      return (this.assetCollection = new AssetCollection(this.get("assets"), {
        projectId: this.id
      }));
    }
    return this.assetCollection.reset(this.get("assets"));
  }

  initialize() {
    this.listenTo(this, "sync change", () => {
      return this.updateAssetsCollection();
    });
    return this.updateAssetsCollection();
  }

  pastDue() {
    const due = this.get("due_date");
    if (due) {
      const dueDate = new Date(due);
      if (dueDate < new Date()) {
        return true;
      }
      return false;
    }
    return false;
  }

  evaluatable() {
    return this.get("evaluatable");
  }
}
