var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<li style=\"width: "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"tickWidth") : depths[1]), depth0))
    + "%\"></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-behavior=\"range\" class=\"range range-tertiary range-editable\">\n	<h4 class=\"range--title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":34}}}) : helper)))
    + "</h4>\n\n	<div class=\"range--track-wrapper\">\n		<div data-behavior=\"range-track\" class=\"range--track\">\n			<ul class=\"range--ticks\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "			</ul>\n			<span data-behavior=\"range-fill\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"percentage") || (depth0 != null ? lookupProperty(depth0,"percentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percentage","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":64}}}) : helper)))
    + "%\" class=\"range--fill\"></span>\n\n			<div class=\"grabber-wrapper\">\n        <span data-behavior=\"range-grabber\" class=\"range--grabber\">\n				  <span style=\"display: none;\" data-behavior=\"placard\" class=\"placard\">\n						<span data-behavior=\"placard-header\" class=\"placard--header\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"placard_title") || (depth0 != null ? lookupProperty(depth0,"placard_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placard_title","hash":{},"data":data,"loc":{"start":{"line":16,"column":67},"end":{"line":16,"column":84}}}) : helper)))
    + "</span>\n					  <span data-behavior=\"placard-content\" class=\"placard--content\">\n						  "
    + alias4(((helper = (helper = lookupProperty(helpers,"placard_desc") || (depth0 != null ? lookupProperty(depth0,"placard_desc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placard_desc","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":24}}}) : helper)))
    + "\n					  </span>\n				  </span>\n        </span>\n			</div>\n		</div>\n		<div class=\"range--handle\">\n			<div class=\"score-wrapper\">\n				<span class=\"score\" data-behavior=\"score\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"score") || (depth0 != null ? lookupProperty(depth0,"score") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"score","hash":{},"data":data,"loc":{"start":{"line":26,"column":46},"end":{"line":26,"column":55}}}) : helper)))
    + "</span>\n				<span class=\"slash\"></span>\n				<span class=\"possible\" data-behavior=\"possible\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"high") || (depth0 != null ? lookupProperty(depth0,"high") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"high","hash":{},"data":data,"loc":{"start":{"line":28,"column":52},"end":{"line":28,"column":60}}}) : helper)))
    + "</span>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});