/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import template from "templates/assets/new_asset.hbs";
import { View, getOption } from "backbone.marionette";
import AssetModel from "models/asset";
import AttachmentModel from "models/attachment";

require("plugins/iframe_transport");
require("plugins/file_upload");

import ShortTextInputView from "views/property_editor/short_text_input";

export default class NewAsset extends View.extend({
  template,

  ui: {
    testNewButton: '[data-behavior="test-new-asset"]',
    hideManage: '[data-behavior="hide-manage"]',
    uploadForm: '[data-behavior="upload-form"]',
    uploadFormWrapper: '[data-behavior="upload-form-wrapper"]',
    fileInputTrigger: '[data-behavior="file-input-trigger"]',
    fileInput: '[data-behavior="file-input"]',
    dropzone: '[data-behavior="dropzone"]',
    uploadStatus: '[data-behavior="upload-status"]',
    uploadStatusDetail: '[data-behavior="upload-status-detail"]',
    keyInput: "input[name=key]",
    policyInput: "input[name=policy]",
    signatureInput: "input[name=signature]",
    assetUploadingMessage: '[data-behavior="asset-uploading-message"]',
    externalVideoUrl: '[data-behavior="external-video-url"]',
    externalVideoSubmit: '[data-behavior="external-video-url-submit"]',
    progressBar: '[data-behavior="progress-bar"]'
  },

  triggers: {
    "click @ui.externalVideoSubmit": "handle:external:video:submit",
    "click @ui.hideManage": "hide:new",
    "click @ui.fileInputTrigger": "show:file:input",
    "submit @ui.externalVideoForm": "handle:external:video:submit"
  },

  regex: {
    youtube: /(v=|\.be\/)([^&#]{5,})/,
    vimeo: /^.+vimeo.com\/(.*\/)?([^#\?]*)/ // eslint-disable-line no-useless-escape
  }
}) {
  onHandleExternalVideoSubmit() {
    const url = this.getUI("externalVideoUrl").val();
    return this.createRemoteVideoAsset(url);
  }

  createRemoteVideoAsset(url) {
    const vimeoMatches = url.match(this.regex.vimeo);
    if (vimeoMatches != null && vimeoMatches.length > 0) {
      this.createVimeoAsset(url);
    } else {
      this.createYoutubeAsset(url);
    }
    return this.getUI("externalVideoUrl").val("");
  }

  createYoutubeAsset(value) {
    const matches = value.match(this.regex.youtube);
    if (
      matches != null &&
      matches.length > 0 &&
      typeof matches[2] === "string"
    ) {
      const asset = this.newAsset({
        external_source: "youtube",
        external_location: matches[2]
      });

      return asset.save(
        {},
        {
          success: () => {
            this.collection.add(asset);
            Radio.channel("app").trigger("error:add", {
              level: "notice",
              msg: "The YouTube video has been saved."
            });
            const onSave = () => {
              return asset.save(
                {},
                {
                  success: () => {
                    Radio.channel("app").trigger("error:add", {
                      level: "error",
                      clear: true,
                      msg: "Media successfully updated."
                    });
                    return this.render();
                  },
                  error: () => {
                    return Radio.channel("app").trigger("error:add", {
                      level: "error",
                      clear: true,
                      msg: "Unable to update media title."
                    });
                  }
                }
              );
            };
            return Radio.channel("app").trigger(
              "modal:open",
              new ShortTextInputView({
                model: asset,
                vent: this.vent,
                onSave,
                property: "name",
                saveLabel: "Update media title",
                inputLabel: "What would you like to call this media?"
              })
            );
          }
        }
      );
    }
    Radio.channel("app").trigger("error:add", {
      level: "error",
      msg: "The Youtube URL you entered is invalid."
    });
    return this.resetUploader();
  }

  createVimeoAsset(value) {
    const matches = value.match(this.regex.vimeo);
    const id = matches ? matches[2] || matches[1] : null;
    if (id) {
      const asset = this.newAsset({
        external_source: "vimeo",
        external_location: id,
      });

      return asset.save(
        {},
        {
          success: () => {
            this.collection.add(asset);
            Radio.channel("app").trigger("error:add", {
              level: "notice",
              msg: "The Vimeo video has been saved."
            });
            const onSave = () => {
              return asset.save(
                {},
                {
                  success: () => {
                    Radio.channel("app").trigger("error:add", {
                      level: "error",
                      clear: true,
                      msg: "Media successfully updated."
                    });
                    return this.render();
                  },
                  error: () => {
                    return Radio.channel("app").trigger("error:add", {
                      level: "error",
                      clear: true,
                      msg: "Unable to update media title."
                    });
                  }
                }
              );
            };
            return Radio.channel("app").trigger(
              "modal:open",
              new ShortTextInputView({
                model: asset,
                vent: this.vent,
                onSave,
                property: "name",
                saveLabel: "Update media title",
                inputLabel: "What would you like to call this media?"
              })
            );
          }
        }
      );
    }
    Radio.channel("app").trigger("error:add", {
      level: "error",
      msg: "The Vimeo URL you entered is invalid."
    });
    return this.resetUploader();
  }

  initialize(options) {
    this.assetable = getOption(this, "assetable");
    return (this.vent = getOption(this, "vent"));
  }

  onShowFileInput() {
    return this.getUI("fileInput").click();
  }

  onHideNew() {
    return this.vent.trigger("hide:new");
  }

  onRender() {
    this.getUI("assetUploadingMessage").hide();
    return this.initializeAsyncUploader();
  }

  hideForm() {
    this.vent.triggerMethod("request:state:uploading");
    this.getUI("assetUploadingMessage").show();
    return this.getUI("uploadFormWrapper").hide();
  }

  showForm() {
    this.vent.triggerMethod("request:state:manage");
    this.getUI("assetUploadingMessage").hide();
    return this.getUI("uploadFormWrapper").show();
  }

  newAsset(attributes) {
    const assetableType = this.assetable.type() === "ProjectModel" ? "Project" : "Submission";
    const asset = new AssetModel(
      Object.assign({}, attributes, {
        assetable_id: this.assetable.id,
        assetable_type: assetableType
      })
    );
    return asset;
  }

  initializeAsyncUploader() {
    let attachment = null;
    return this.getUI("uploadForm").fileupload({
      multipart: true,
      limitMultiFileUploads: 1,
      limitConcurrentUploads: 1,
      autoUpload: true,
      add: (e, uploadForm) => {
        const file = uploadForm.files[0];
        if (file.size > 5368709120) {  // Max file size 5GB
          Radio.channel("app").trigger("error:add", {
            level: "error",
            msg: "File too large. Limit is 5GB"
          });
          return;
        }
        if (this.fileTypesRegex().test(file.name)) {
          this.hideForm();
          attachment = new AttachmentModel({});
          return attachment.save(
            { media_file_name: file.name },
            {
              success: model => {
                const uploadDocument = attachment.get("s3_upload_document");
                this.getUI("keyInput").val(uploadDocument.key);
                this.getUI("policyInput").val(uploadDocument.policy);
                this.getUI("signatureInput").val(uploadDocument.signature);
                uploadForm.submit();
                this.getUI("uploadStatus").html("Uploading...");
                return this.getUI("uploadStatusDetail").html("");
              },
              error: (model, response) => {
                Radio.channel("app").trigger("error:add", {
                  level: "error",
                  msg: "Unable to create new attachment model."
                });
                return this.resetUploader();
              }
            }
          );
        }
        Radio.channel("app").trigger("error:add", {
          level: "error",
          msg: `Invalid file extension. Extension must be one of: ${this.model
            .get("allowed_extensions")
            .join(", ")}.`
        });
        return this.resetUploader();
      },
      progress: (e, data) => {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        this.getUI("uploadStatus").html("Uploading...");
        this.getUI("uploadStatusDetail").html(
          `${this.toFileSize(data.loaded)} out of ${this.toFileSize(
            data.total
          )}`
        );
        return this.getUI("progressBar").width(`${progress}%`);
      },
      fail: (e, data) => {
        Radio.channel("app").trigger("error:add", {
          level: "error",
          msg:
            "Unable to upload. Please check your internet connection or try again later."
        });
        return this.resetUploader();
      },
      done: (e, data) => {
        const asset = this.newAsset({ attachment_id: attachment.id });
        this.getUI("uploadStatus").html("Saving media to Vocat...");
        this.getUI("uploadStatusDetail").html("Please wait.");
        return asset.save(
          {},
          {
            success: () => {
              this.collection.add(asset);
              asset.poll();
              Radio.channel("app").trigger("error:add", {
                level: "error",
                clear: true,
                msg: "Media successfully saved."
              });
              const onSave = () => {
                return asset.save(
                  {},
                  {
                    success: () => {
                      Radio.channel("app").trigger("error:add", {
                        level: "error",
                        clear: true,
                        msg: "Media successfully updated."
                      });
                      return this.render();
                    },
                    error: () => {
                      return Radio.channel("app").trigger("error:add", {
                        level: "error",
                        clear: true,
                        msg: "Unable to update media title."
                      });
                    }
                  }
                );
              };
              Radio.channel("app").trigger(
                "modal:open",
                new ShortTextInputView({
                  model: asset,
                  vent: this.vent,
                  onSave,
                  property: "name",
                  saveLabel: "Update media title",
                  inputLabel: "What would you like to call this media?"
                })
              );
              return this.resetUploader();
            },
            error: () => {
              Radio.channel("app").trigger("error:add", {
                level: "error",
                clear: true,
                msg: "The server was unable to save the media."
              });
              return this.resetUploader();
            }
          }
        );
      }
    });
  }

  toFileSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "O bytes";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return (bytes / 1024 ** i).toFixed(2) + "" + sizes[i];
  }

  fileTypesRegex() {
    let types = this.model.get("allowed_extensions");
    if (types.length === 0) return /.*/;
    types = types.join("|");
    const out = `(\\.|\\/)(${types})$`;
    return new RegExp(out, "i");
  }

  resetUploader() {
    this.getUI("uploadForm").fileupload("destroy");
    this.render();
    return this.showForm();
  }

  serializeData() {
    const context = super.serializeData();
    const sd = {
      AWSS3Url: VocatS3Url, // eslint-disable-line no-undef
      AWSPublicKey: VocatAWSPublicKey, // eslint-disable-line no-undef
      project: context
    };
    return sd;
  }
}
