import template from "templates/discussion/post.hbs";
import DiscussionPostCollection from "collections/discussion_post_collection";
import AbstractDiscussionView from "views/abstract/abstract_discussion";
import PostCollectionView from "views/discussion/post_collection";

export default class PostView extends AbstractDiscussionView.extend({
  tagName: "li",
  template,
  ui: {
    bodyInput: '[data-behavior="post-input"]',
    inputToggle: '[data-behavior="input-container"]'
  },
  events: {
    'click [data-behavior="delete"]': "onClickPostDelete"
  },
  triggers: {
    'click [data-behavior="toggle-reply"]': "reply:toggle",
    'click [data-behavior="toggle-delete-confirm"]': "confirm:delete:toggle",
    'click [data-behavior="post-save"]': "post:save"
  },
  regions: {
    discussion: '[data-behavior="post-container"]'
  }
}) {
  initialize(options) {
    this.submission = options.submission;
    this.allPosts = options.allPosts;
    this.collection = new DiscussionPostCollection([], {});
    this.collection.reset(this.allPosts.where({ parent_id: this.model.id }));
  }

  onRender() {
    if (!this.collection.length) return null;

    const discussionView = new PostCollectionView({
      collection: this.collection,
      submission: this.submission,
      allPosts: this.allPosts
    });

    this.showChildView("discussion", discussionView);
  }

  onConfirmDeleteToggle() {
    return this.$el
      .find('[data-behavior="delete-confirm"]:first')
      .slideToggle(150);
  }

  onReplyToggle() {
    this.getUI("inputToggle").slideToggle({ duration: 200 });
  }

  onClickPostDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    this.allPosts.remove(this.model);
    this.model.destroy({ wait: true });
  }

  handleDestroyedPost() {
    return this.$el.fadeOut(250, () => {
      return this.remove();
    });
  }
}
