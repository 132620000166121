var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"Asset::Youtube",{"name":"if_match","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"Asset::Vimeo",{"name":"if_match","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"Asset::Video",{"name":"if_match","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":17,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <video id=\"video-player\" data-behavior=\"video-player\" class=\"video-js vjs-default-skin\" controls preload=\"auto\"\n                   data-setup='{ \"techOrder\": [\"youtube\"], \"sources\": [{ \"type\": \"video/youtube\", \"src\": \""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\"}], \"youtube\": { \"iv_load_policy\": 3 } }'>\n            </video>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <video id=\"video-player\" data-behavior=\"video-player\" class=\"video-js vjs-default-skin\" controls preload=\"auto\" data-setup='{ \"techOrder\": [\"vimeo\"], \"sources\": [{ \"type\": \"video/vimeo\", \"src\": \""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\"}] }'></video>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <video id=\"video-player\" data-behavior=\"video-player\" class=\"video-js vjs-default-skin\" controls preload=\"auto\" data-setup='{ \"techOrder\": [\"html5\"], \"sources\": [{ \"type\": \"video/mp4\", \"src\": \""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"mp4") : stack1), depth0))
    + "\"}] }'></video>\n            <!--\n            <source src=\"/sample_transcoded.mp4\" type=\"video/mp4\" />\n            <source src=\"/sample_transcoded.webm\" type=\"video/webm\" />\n            -->\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<audio id=\"video-player\" data-behavior=\"video-player\" class=\"video-js vjs-default-skin\" controls preload=\"auto\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"mp3") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":3},"end":{"line":23,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"mp4") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":26,"column":10}}})) != null ? stack1 : "")
    + "		</audio>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<source src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"mp3") : stack1), depth0))
    + "\" type=\"audio/mp3\"/>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<source src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? lookupProperty(stack1,"mp4") : stack1), depth0))
    + "\" type=\"audio/mp4\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-behavior=\"player-container\" class=\"player-container\">\n"
    + ((stack1 = __default(require("../../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"family") : depth0),"video",{"name":"if_match","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":18,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/if_match.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"family") : depth0),"audio",{"name":"if_match","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":1},"end":{"line":28,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});